.class-card {
	--bs-btn-border-radius: 1.25rem;

	background-color: $white;
	border-radius: 1.25rem;
	border: 0.0625rem solid $shades-400;
	padding: 1.25rem;
	gap: 3rem;
	min-height: 15rem;
	text-decoration: none;
	color: $primary;
	transition-property: border-color, box-shadow;
	transition-duration: $reg-trans;
	transition-timing-function: ease;

	// no hover state should be triggered when hovering on 'More' button or the <Dropdown /> element
	&:has(.stretched-link):not(:has(.btn-dropdown:hover, .btn-dropdown + div:hover)):hover {
		border-color: $hero-blue;
		box-shadow: 0 0 0 0.125rem rgba(0, 183, 241, 0.2);

		.title {
			color: $vivid-blue;
			text-decoration-color: $vivid-blue;
		}
	}

	.teacher-icon,
	.students-icon {
		width: 3rem;
		height: 3rem;
		border-radius: 0.75rem;
		padding: 0.75rem;
	}

	.students-icon {
		background-color: $stage5-light;

		&.archive {
			background-color: $shades-200;
		}
	}

	.teacher-icon {
		background-color: $b1-pale;

		&.unassigned {
			background-color: $shades-200;
		}
	}

	.actions-dropdown {
		right: 0;
		top: calc(100% + 0.25rem);
	}

	.title {
		text-decoration-color: transparent;
		transition-property: text-decoration-color, color;
		transition-duration: $reg-trans;
		transition-timing-function: ease;
	}
}
