@mixin h1 {
	font-size: 2.75rem;
	font-weight: 600;
	line-height: 1.05;
	letter-spacing: -0.055rem;

	&.display {
		font-size: 3.25rem;
		font-weight: 700;
		line-height: 1;
		letter-spacing: -0.08rem;

		@include media-breakpoint-up(lg) {
			font-size: 4rem;
		}
	}

	strong {
		font-weight: 700;
	}
}

@mixin h2 {
	font-size: 2rem;
	line-height: 1.15;
	font-weight: 600;
	letter-spacing: -0.04rem;

	strong {
		font-weight: 700;
	}
}

@mixin h3 {
	font-size: 1.5rem;
	line-height: 1.2;
	font-weight: 600;

	strong {
		font-weight: 700;
	}
}

@mixin h4 {
	font-size: 1.125rem;
	line-height: 1.25;
	font-weight: 700;
}

@mixin h5 {
	font-size: 0.8175rem;
	letter-spacing: 0.25rem;
	font-weight: 700;
	line-height: 1.2;
}

@mixin body-tiny {
	font-size: 0.75rem;
	line-height: 1.2;
}

@mixin body-small {
	font-size: 0.875rem;
	line-height: 1.5;
}

@mixin body-large {
	font-size: 1.125rem;
}

@mixin label-navigation {
	font-family: $quicksand;
	font-size: 10px;
	font-weight: 700;
	letter-spacing: 0.5px;
	text-transform: uppercase;
}

@mixin nav-link {
	font-family: $quicksand;
	font-size: 0.875rem;
	font-weight: 700;
	line-height: 1.15;
	text-decoration: none;
}

@mixin label {
	font-size: 0.8125rem;
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: -0.008125rem;
}

h1,
.h1 {
	@include h1;
}

h2,
.h2 {
	@include h2;
}

h3,
.h3 {
	@include h3;
}

h4,
.h4 {
	@include h4;
}

.body-tiny {
	@include body-tiny;
}

.body-small {
	@include body-small;
}

.body-large {
	@include body-large;
}

.body-normal {
	font-size: var(--base-font-size) !important;
	line-height: var(--base-line-height) !important;
}

a {
	@include text-link;
	font-size: inherit;
	line-height: inherit;

	&.no-pseudo-hover {
		position: static;

		&::before {
			display: none;
		}
	}
}

p {
	margin-bottom: var(--p-spacing);
}

label {
	cursor: pointer; // pointer-state for labels, since they should always activate some kind of input
}

.label-navigation {
	@include label-navigation;
}

.label {
	@include label;
}

.label-tag {
	@include label;
	color: $primary;
	background-color: $white;
	border: 0.0625rem solid currentColor;
	padding: 0.1875rem 0.25rem;
	border-radius: 0.25rem;
}

.text-shades-400 {
	color: $shades-400 !important;
}

.text-shades-500 {
	color: $shades-500 !important;
}

.text-shades-600 {
	color: $shades-600 !important;
}

.text-shades-800 {
	color: $shades-800 !important;
}

.text-vivid-blue {
	color: $vivid-blue !important;
}

.text-hero-blue {
	color: $hero-blue !important;
}

.text-light-blue {
	color: $light-blue !important;
}

.text-hero-rose {
	color: $hero-rose !important;
}

.text-milo-blue {
	color: $milo-blue !important;
}

.text-dark-text {
	color: $dark-text !important;
}

.text-new-line-wrap {
	white-space: pre-line;
}

.text-danger,
.text-error {
	color: $error !important; // fighting Bootstrap, as danger-bg and danger-text are different colours in the design
}
