.page-staff-library {
	.staff-book {
		padding: 0.25rem 0.25rem 0.75rem;
		border: 0.0625rem solid transparent;
		border-radius: 0.5rem;
		transition-property: border-color, box-shadow;
		transition-duration: $reg-trans;
		transition-timing-function: ease;

		&:has(.stretched-link):not(:has(.btn-favorite:hover)):hover {
			border-color: $hero-blue;
			box-shadow: 0 0 0 0.125rem rgba($hero-blue, 0.2);
		}
	}

	.stretched-link {
		position: static;

		&::before {
			display: none;
		}
	}

	.btn-favorite {
		--bs-btn-bg: #{$white};
		--bs-btn-color: #{$shades-500};
		--bs-btn-border-color: transparent;
		--bs-btn-hover-bg: var(--bs-btn-bg);
		--bs-btn-hover-color: #{$alert-amber};
		--bs-btn-active-bg: var(--bs-btn-bg);
		--bs-btn-active-color: var(--bs-btn-hover-color);
		--bs-btn-disabled-bg: #{$white};
		--bs-btn-disabled-color: #{rgba($dark-text, 0.2)};
		--bs-btn-disabled-opacity: 1;
		--bs-btn-focus-box-shadow: none;
		border-radius: 0.5rem 0 0 0;
		padding: 0.125rem;
		width: 2rem;
		height: 2rem;

		&.is-favorited {
			--bs-btn-color: #{$alert-amber};
			--bs-btn-hover-color: #{$shades-400};

			i {
				mask-image: url(../../../../public/svg/star-filled.svg) !important;
			}
		}
	}

	.book-image {
		width: 11.0625rem;
		height: 13.6875rem;
	}
}
