.page-buyer-journey-complete {
	padding: 40px 0;
	color: $shades-800;
	font-size: 16px; // overwriting base-font-size used in main product

	@include media-breakpoint-up(md) {
		padding: 80px 0;
	}

	.logo {
		margin-bottom: 40px;

		@include media-breakpoint-up(md) {
			margin-bottom: 80px;
		}
	}

	.heading-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 24px;
		margin-bottom: 40px;

		@include media-breakpoint-up(md) {
			margin-bottom: 80px;
		}

		p {
			color: $shades-500;
			width: 50%;
		}
	}

	.content-wrapper {
		border-radius: 40px;
		border: 1px solid $b2-light;
		background-color: $white;
		padding: 20px;
		margin-left: auto;
		margin-right: auto;

		@include media-breakpoint-up(md) {
			max-width: 1040px;
			padding: 40px;
		}
	}

	.divider {
		width: 100%;
		height: 1px;
		border-top: 1px solid $shades-400;
		margin: 20px 0;

		@include media-breakpoint-up(md) {
			margin: 40px 0;
		}
	}

	.title-section {
		display: flex;
		flex-direction: column;
		align-items: center;

		@include media-breakpoint-up(md) {
			flex-direction: row;
			justify-content: space-between;
		}

		.title-left {
			text-align: center;
			margin-bottom: 20px;

			@include media-breakpoint-up(md) {
				text-align: left;
				margin-bottom: 0;
			}
		}

		.order-number {
			color: $shades-500;
		}
	}

	.email-notification-box {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		color: $b3-vivid;
		background-color: $b1-pale;
		padding: 10px 12px;
		border-radius: 8px;
		margin-top: 20px;

		@include media-breakpoint-up(md) {
			flex-direction: row;
			margin-top: 40px;
			gap: 12px;
			padding: 12px 16px;
			align-items: center;
			justify-self: flex-start;
		}

		i {
			margin-block: 0.25rem;
		}

		.text {
			margin-bottom: 0;
			font-weight: 500;
		}
	}

	.detail-section {
		.section-name {
			font-weight: 700;
			margin-bottom: 20px;

			@include media-breakpoint-up(md) {
				margin-bottom: 40px;
			}
		}

		.row {
			&:not(:last-child) {
				margin-bottom: 12px;
			}
		}

		p {
			margin-bottom: 0;
			font-weight: 500;
			font-size: 16px;
			line-height: 1.4;
		}
	}

	.summary-box {
		border-radius: 20px;
		border: 1px solid $b2-light;
		padding: 20px;
		background-color: $aura-blue;

		@include media-breakpoint-up(md) {
			padding: 40px;
		}

		.divider {
			border-top: 1px solid $b2-light;
		}

		.plan-info {
			margin-bottom: 4px;

			@include media-breakpoint-up(md) {
				margin-bottom: 12px;
			}
		}

		.user-number {
			margin-left: 0;
			margin-bottom: 0;
			margin-top: 4px;
			color: $shades-500;

			@include media-breakpoint-up(md) {
				margin-left: 12px;
				margin-top: 0;
			}
		}

		.promo-info {
			p {
				margin-left: 12px;
				font-weight: 700;
			}
		}

		.discount-amount {
			font-weight: 700;
			display: block;
		}

		.total-text {
			color: $shades-500;
			margin-top: 12px;
			font-weight: 700;
		}
	}
}
