.dropdown {
	.dropdown-menu {
		gap: 0.25rem;
		box-shadow: var(--bs-dropdown-box-shadow);

		&.show {
			display: flex;
			flex-direction: column;
		}

		.dropdown-item {
			font-size: var(--bs-dropdown-font-size);
			font-weight: 700;
			border-radius: 0.375rem;

			&.option-destructive {
				color: $hero-rose;

				&:hover {
					background-color: rgba($color: $stage2-light, $alpha: 0.5);
					color: $stage2-dark;
				}
			}
		}
	}
}
