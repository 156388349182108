/* ==========================================================================
   Print styles.
   In-lined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */

@media print {
	* {
		background: transparent !important;
		color: #000 !important; /* Black prints faster: h5bp.com/s */
		box-shadow: none !important;
		text-shadow: none !important;
	}

	body {
		font-family: Arial, Helvetica, sans-serif;
	}

	a {
		text-decoration: underline;

		&:visited {
			text-decoration: underline;
		}

		&[href]::after {
			content: " (" attr(href) ")";
		}
	}

	abbr[title]::after {
		content: " (" attr(title) ")";
	}

	/*
	* Don't show links for images, or javascript/internal links
	*/

	a[href^="javascript:"]::after,
	a[href^="#"]::after {
		content: "";
	}

	pre,
	blockquote {
		border: 0.0625rem solid #999;
		page-break-inside: avoid;
	}

	thead {
		display: table-header-group; /* h5bp.com/t */
	}

	tr,
	img {
		page-break-inside: avoid;
	}

	img {
		max-width: 100% !important;
	}

	p,
	h2,
	h3 {
		orphans: 3;
		widows: 3;
	}

	h2,
	h3 {
		page-break-after: avoid;
	}

	header.header,
	footer.footer {
		display: none !important;
	}

	.main {
		margin: 0 !important;
	}
}
