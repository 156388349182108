.layout-staff-dashboard {
	.side-nav {
		display: flex;
		flex-flow: column nowrap;
		height: 100%;
		flex-basis: 10rem; // flex-basis rather than width will allow sidebar to adjust its width to fit the content as best it can
		flex-shrink: 0;

		+ .main-content {
			padding-bottom: 5rem; // extra space to account for error messages that are fixed to the bottom right
		}

		.logo-link {
			padding: 0.75rem 1rem;

			&::before {
				display: none;
			}
		}

		.main-links-wrapper {
			background-color: $b1-pale;
			border-width: 0.0625rem 0.0625rem 0 0;
			border-style: solid;
			border-color: $b2-light;
			border-radius: 0 1rem 0 0;
			height: 100%;

			.nav-link-text {
				@include nav-link;
				--bs-btn-bg: transparent;
				--bs-btn-color: var(--brand-tertiary);
				--bs-btn-hover-bg: #{rgba($vivid-blue-tinted, 0.2)};
				--bs-btn-hover-color: var(--bs-btn-color);
				--bs-btn-border-radius: 0.5rem;
				--bs-btn-focus-box-shadow: none;
				justify-content: flex-start;
				gap: 0.625rem;
				padding: 0.5rem;
				margin: 0.125rem 0; // a touch of margin so hover states don't overlay each other
				color: $primary;
				text-decoration: none;
				width: 100%;
			}
		}

		.icon-wrapper.show-dot {
			position: relative;

			&::after {
				content: "";
				position: absolute;
				top: 0;
				right: 0;
				transform: translate(0.25rem, -0.25rem);
				width: 0.5rem;
				height: 0.5rem;
				border-radius: 50%;
				background-color: $hero-rose;
			}
		}
	}
}
