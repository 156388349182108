.chip {
	--bs-badge-padding-x: 0.5rem;
	--bs-badge-padding-y: 0.5rem;
	--bs-badge-font-size: 0.8125rem;
	--bs-badge-font-weight: 700;
	--bs-badge-color: $dark-text;
	--bs-badge-border-radius: 1rem;

	background-color: $b1-pale;
	display: inline-flex;
	align-items: center;
	height: 2rem;
	gap: 0.5rem;
	padding-left: 0.75rem;
	border: 0.0625rem solid transparent;

	&:hover {
		border: 0.0625rem solid $hero-blue;
		color: $shades-800;
	}

	&:focus {
		border: 0.0625rem solid $shades-200;
		background-color: transparent;
	}

	.email {
		border: 0;
		background-color: transparent;
		width: 100%;
		height: 100%;
		padding: 0;
		font-size: 0.8125rem;
		font-weight: 700;
		color: $dark-text;

		&:focus {
			outline: none;
		}

		&:hover {
			color: $b3-vivid;
		}
	}

	.error-icon {
		@extend %svg-mask;

		--svg-mask-image: url("../../../public/svg/alert-solid.svg");

		display: flex;
		color: $danger;
		justify-content: center;
		align-items: center;
		background-size: 1.5rem;
		min-width: 1.5rem;
		height: 1.5rem;
	}

	.close {
		color: $primary;
		min-width: 1.5rem;
		height: 1.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 0.75rem;
		cursor: pointer;

		&:hover {
			background-color: $hero-blue !important;
			color: $white;
		}
	}

	&.error {
		background-color: rgba($color: $stage2-light, $alpha: 0.5);
		padding-left: 0.25rem;

		&:hover {
			--bs-badge-color: $stage2-dark;

			border: 0.0625rem solid $alert-red;
			background-color: rgba($color: $stage2-light, $alpha: 1);

			.email {
				color: $stage2-dark;
			}
		}

		.close {
			&:hover {
				color: $white;
				background-color: $alert-red !important;
			}
		}
	}
}
