.quiz-select-right-answer {
	.answer-text-box,
	.answer-image-box {
		--pulse-distance: 1rem;
		--line-color-rgb: #{red($hero-blue), green($hero-blue), blue($hero-blue)};
		--audio-playing-box-shadow: 0 0 0 calc(var(--pulse-distance) * 1) rgba(var(--line-color-rgb), 0.05), 0 0 0 calc(var(--pulse-distance) * 2) rgba(var(--line-color-rgb), 0.05),
			0 0 0 calc(var(--pulse-distance) * 3) rgba(var(--line-color-rgb), 0.05), 0 0 0 calc(var(--pulse-distance) * 4) rgba(var(--line-color-rgb), 0.02);
		border-radius: 1rem;
		transition: background-color $reg-trans ease, box-shadow 0.75s ease, transform $reg-trans ease;

		&.audio-playing {
			box-shadow: var(--audio-playing-box-shadow), 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);

			.text-answer {
				color: $vivid-blue;
			}

			.answer-underscores {
				text-decoration-color: $vivid-blue;
			}
		}
	}

	.answer-text-box {
		margin-bottom: 5rem;
		padding: 2.5rem 5rem;
		background-color: $white;
		box-shadow: 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);

		@include media-breakpoint-up(lg) {
			padding-block: 5rem;
		}

		.btn-audio-icon {
			top: 1.25rem;
			left: 1.25rem;
		}

		.answer-underscores {
			color: transparent;
			text-decoration: underline;
			text-decoration-color: $shades-800;
			transition: text-decoration-color $reg-trans ease;

			&::selection {
				color: $shades-800;
			}
		}
	}

	.answer-image-box {
		margin-bottom: 3rem;
		padding: 1.25rem;

		@include media-breakpoint-up(lg) {
			margin-bottom: 4rem;
		}

		&.show-placeholder {
			padding: 0;

			.placeholder {
				width: 17.5rem;
				aspect-ratio: 1;
				background-color: $shades-200;
				border-radius: 0.5rem;
			}

			.btn-audio-icon {
				right: calc(100% + 1rem);
			}
		}

		&.audio-playing {
			background-color: $white;
			box-shadow: var(--audio-playing-box-shadow);
		}

		.btn-audio-icon {
			top: 0;
			right: calc(100% + 0.5rem); // using right to account for width of btn icon
		}
	}

	.text-answer {
		transition: color $long-trans ease;
	}

	.btn-option {
		--bs-btn-padding-y: 2.25rem;

		&.image-button {
			--bs-btn-padding-y: 1.25rem;
			--bs-btn-padding-x: 1.25rem;
			box-shadow: 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);

			&:not(.active, .error, .success) {
				--bs-btn-bg: #{$white};
			}

			&.active {
				background-color: $light-blue;
			}

			img {
				max-width: none;
				object-fit: scale-down;
				mix-blend-mode: multiply;
			}
		}

		&.active {
			box-shadow: 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);

			&.audio-playing {
				box-shadow: var(--audio-playing-box-shadow), 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);
			}
		}
	}
}
