.image-preloader {
	width: 15rem;
	padding: 2.5rem;
	background: $aura-blue;
	border: 1px solid $vivid-blue;
	border-radius: 2.5rem;
	box-shadow: 0 0.25rem 1.5rem 0 rgba($hero-blue, 0.2);

	.spinner-blue-ring {
		--bs-spinner-width: 3rem;
		--bs-spinner-height: 3rem;
		--bs-spinner-border-width: 0.35rem;
		border-right-color: $light-blue;
	}
}
