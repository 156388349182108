.page-book-reader {
	--container-gutter-width: 200px; // to prevent book arrows disappearing through side of viewport at smaller screens

	display: flex;
	align-items: center;
	flex-grow: 1;
	background-color: rgba($b1-pale, 0.5);
	overflow: hidden;
	transition: background-color $reg-trans ease;

	&.is-zoomed {
		background-color: $shades-500;
	}

	.alert-mouse-drag {
		position: absolute;
		left: 50%;
		bottom: 1.25rem;
		transform: translate(-50%);
		display: inline-flex;
		padding: 0.75rem 1.25rem;
		align-items: center;
		justify-content: center;
		gap: 0.75rem;
		border-radius: 0.75rem;
		color: $white;
		background-color: rgba(24, 28, 33, 0.8);
		box-shadow: 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);
		pointer-events: none;
	}

	.btn-zoom {
		position: static;

		i {
			--svg-mask-image: url(./../../../public/svg/zoom-in.svg);
		}

		@at-root .is-zoomed#{&} {
			--bs-btn-bg: #{$dark-text};
			--bs-btn-color: #{$white};

			i {
				--svg-mask-image: url(./../../../public/svg/zoom-out.svg);
			}
		}
	}

	.btn-info {
		--bs-btn-bg: transparent;
		--bs-btn-color: #{$dark-text};
		--bs-btn-border-color: transparent;
		--bs-btn-hover-bg: var(--bs-btn-bg);
		--bs-btn-hover-color: #{$hero-blue};
		--bs-btn-hover-border-color: var(--bs-btn-border-color);
		border-top-right-radius: 0;
		border-bottom-right-radius: 0;
	}
}
