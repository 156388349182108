.bordered-list {
	+ .bordered-list {
		margin-top: 2.5rem;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 0.0625rem solid $shades-400;
		border-radius: 1.25rem;
		padding: 1.5rem 0.5rem;
		background-color: $shades-100;

		&:has(+ .content) {
			border-bottom-left-radius: 0;
			border-bottom-right-radius: 0;
		}

		+ .content {
			border-top-width: 0;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
		}
	}

	.content {
		min-height: 6rem;
		padding-block: 1.5rem;
		background-color: $white;
		border: solid $shades-400;
		border-width: 0.0625rem;

		&:first-child {
			border-start-start-radius: 1.25rem;
			border-start-end-radius: 1.25rem;
		}

		&:last-child {
			border-bottom-left-radius: 1.25rem;
			border-bottom-right-radius: 1.25rem;
		}

		+ .content {
			border-top-width: 0;
		}
	}
}
