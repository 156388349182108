.title-section-main,
.title-section-sub {
	background: linear-gradient(270deg, $white 0%, $aura-blue 100%);
}

.title-section-main {
	position: relative;
	z-index: $title-section-main;
	padding-bottom: 1.25rem;

	.description {
		margin-bottom: 0;
	}

	&:not(.has-children) {
		margin-bottom: 2.5rem;
		padding-bottom: 1.25rem;
		box-shadow: 0 0.5rem 1.25rem 0 rgba(0, 120, 208, 0.1);
		border-bottom: 0.0625rem solid $shades-200;
	}

	&:not(.has-title) {
		display: flex;
		align-items: center;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}

	.left-title-group,
	.right-button-group {
		padding-top: 2.5rem;
	}

	.left-title-group {
		display: flex;
		flex-flow: column nowrap;
		align-items: flex-start;
		justify-content: flex-start;
		gap: 0.86rem;
	}

	.right-button-group {
		display: flex;
		flex-flow: column nowrap;
		gap: 0.75rem;
	}
}

.title-section-sub {
	position: sticky;
	top: 0;
	z-index: $title-section-sub; // to be above accordions, which are z-index: 3 by default
	margin-bottom: 2.5rem;
	padding: 1rem 0;
	box-shadow: 0 0.5rem 1.25rem 0 rgba(0, 120, 208, 0.1);
	border-bottom: 0.0625rem solid $shades-200;

	&.title-bar-hidden {
		padding: 2rem 0;
	}

	.dropdown {
		.btn,
		.dropdown-menu {
			min-width: 15rem;
		}

		.btn {
			justify-content: space-between;
		}
	}
}

.notification-dot {
	width: 0.5rem;
	height: 0.5rem;
	border-radius: 50%;
	background-color: $hero-rose;
}
