.page-plans {
	.btn-icon {
		--bs-btn-bg: #{$b1-pale};
		--bs-btn-color: #{$shades-800};
		--bs-btn-border-color: transparent;
		--bs-btn-hover-bg: #{$hero-blue};
		--bs-btn-hover-color: #{$white};
		--bs-btn-disabled-opacity: 0.4;
		--bs-btn-disabled-bg: var(--bs-btn-bg);
		--bs-btn-disabled-color: var(--bs-btn-color);
	}

	.dot {
		font-size: 1.125rem;
		font-style: normal;
		font-weight: 700;
		line-height: 125%;
	}

	.btn-dropdown,
	.dropdown-menu {
		min-width: auto !important;
	}

	.planner-wrapper {
		--main-stage-color: #{$stage-1};
		--main-stage-color-light: #{$stage1-light};
		--main-stage-color-dark: #{$stage1-dark};
		--main-stage-text: #{$primary};

		box-shadow: 0 0 0 0.5rem rgba(0, 183, 241, 0.1);
		border-radius: 1rem;
		margin-bottom: 2.5rem;

		&.stage-2 {
			--main-stage-color-light: #{$stage2-light};
			--main-stage-color-dark: #{$stage2-dark};
			--main-stage-color: #{$stage2};
			--main-stage-text: #{$white};
		}

		&.stage-3 {
			--main-stage-color-light: #{$stage3-light};
			--main-stage-color-dark: #{$stage3-dark};
			--main-stage-color: #{$stage3};
			--main-stage-text: #{$white};
		}

		&.stage-4 {
			--main-stage-color-light: #{$stage4-light};
			--main-stage-color-dark: #{$stage4-dark};
			--main-stage-color: #{$stage4};
			--main-stage-text: #{$white};
		}

		&.stage-\+4 {
			--main-stage-color-light: #{$stage4-plus-light};
			--main-stage-color-dark: #{$stage4-plus-dark};
			--main-stage-color: #{$stage4-plus};
			--main-stage-text: #{$white};
		}

		&.stage-5 {
			--main-stage-color-light: #{$stage5-light};
			--main-stage-color-dark: #{$stage5-dark};
			--main-stage-color: #{$stage5};
			--main-stage-text: #{$white};
		}

		&.stage-6 {
			--main-stage-color-light: #{$stage6-light};
			--main-stage-color-dark: #{$stage6-dark};
			--main-stage-color: #{$stage6};
			--main-stage-text: #{$white};
		}

		&[class*="stage-7"] {
			--main-stage-color-light: #{$stage7-light};
			--main-stage-color-dark: #{$stage7-dark};
			--main-stage-color: #{$stage7};
			--main-stage-text: #{$white};
		}

		&[class*="stage-8"] {
			--main-stage-color-light: #{$stage8-light};
			--main-stage-color: #{$stage8};
			--main-stage-text: #{$white};
		}

		&.stage-Revision {
			--main-stage-color-light: #{lighten($milo-blue, 20%)}; // design hasn't supplied one, but this looks great
			--main-stage-color-dark: #{darken($milo-blue, 20%)};
			--main-stage-color: #{$milo-blue};
			--main-stage-text: #{$white};
		}
	}

	.weekly-planner {
		.day-title-wrapper {
			background-color: var(--main-stage-color);
			color: var(--main-stage-text);
			flex: 1;
			width: 20%;
			transition: background-color $reg-trans ease;

			&:first-child {
				border-radius: 1rem 0 0;
			}

			&:last-child {
				border-radius: 0 1rem 0 0;
			}

			&:has(.btn-play-lesson:is(:hover, :focus-visible)) {
				background-color: var(--main-stage-color-dark);

				.btn-play-lesson {
					padding-left: 0.5rem;

					.label {
						position: static;
						opacity: 1;
						visibility: visible;
					}
				}
			}

			.btn-play-lesson {
				--bs-btn-color: currentColor;
				--bs-btn-bg: var(--main-stage-color-dark);
				--bs-btn-hover-color: currentColor;
				--bs-btn-hover-bg: rgba(0, 0, 0, 0.2);
				padding: 0.375rem;

				.label {
					position: absolute;
					pointer-events: none;
					opacity: 0;
					visibility: hidden;
					transition: opacity $reg-trans ease;
				}
			}

			.day-title {
				height: 3rem;
			}
		}
	}

	.details-wrapper {
		border: 0.0625rem solid var(--main-stage-color);
		border-radius: 0 0 1rem 1rem;
		overflow: hidden;

		.activity-column {
			border: 0 solid var(--main-stage-color);
			border-width: 0 0.0625rem 0 0;
			background-color: $white;
			padding: 0.75rem;
			width: 20%;

			.time-title {
				display: flex;
				flex-direction: column;
				gap: 0.5rem;
				min-height: 1.5rem;

				@include media-breakpoint-up(lg) {
					flex-direction: row;
					align-items: center;
				}
			}

			&:last-child {
				border-right-width: 0;
			}

			.single-divider {
				border-bottom: 0.0625rem solid $shades-200;
				flex: 1;
			}

			.activity-time {
				line-height: 1.2;
			}

			.activity {
				--bs-link-color-rgb: #{$primary-rgb};
				--activity-box-padding-y: 0.25rem;
				padding: 0.125rem;
				background-color: var(--main-stage-color-light);
				border-radius: 0.5rem;
				position: relative;
				text-decoration: none;
				transition: background-color $reg-trans ease;

				&::before {
					content: "";
					display: block;
					width: 0.125rem;
					height: auto;
					background-color: var(--main-stage-color);
					border-radius: 0.0625rem;
					position: absolute;
					left: 0;
					top: calc(var(--activity-box-padding-y) * 2);
					bottom: calc(var(--activity-box-padding-y) * 2);
					transform: translate(0);
				}

				&[href] {
					&:hover,
					&:focus-visible {
						background-color: var(--main-stage-color);
					}
				}

				.activity-box {
					background-color: rgba($color: $white, $alpha: 0.6);
					border-radius: 0.375rem;
					padding: var(--activity-box-padding-y) 0.25rem var(--activity-box-padding-y) 0.5rem;
					min-height: 2.25rem;

					@include media-breakpoint-up(lg) {
						padding-left: 0.75rem;
					}

					.activity-type-icon {
						display: flex;
						align-items: center;
						justify-content: center;
						flex-shrink: 0;
						border-radius: 0.375rem;
						border-top: 0.0625rem solid var(--main-stage-color);
						border-right: 0.0625rem solid var(--main-stage-color);
						border-bottom: 0.125rem solid var(--main-stage-color);
						border-left: 0.0625rem solid var(--main-stage-color);
						background: $white;
						height: 1.75rem;
						width: 1.75rem;
					}
				}
			}
		}

		.description {
			background-color: $shades-100;
			padding-inline: 1rem;
		}
	}
}

.footnotes {
	background-color: $b1-pale;
	border-radius: 1rem;
}

// Offcanvas
.teaching-sequence {
	--bs-offcanvas-width: 1024px;
	--bs-offcanvas-padding-y: 2.5rem;

	.offcanvas-header {
		padding-bottom: 1.25rem;
	}

	.tab-wrapper {
		padding: 0.25rem;
		border-radius: 1.75rem;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $white;
		margin-top: 2.5rem;
	}

	.scroll-gradient {
		position: absolute;
		top: 100%;
		left: 0;
		z-index: calc(var(--bs-offcanvas-zindex) + 1);
		min-height: 2.5rem;
		width: 100%;
		background: linear-gradient(180deg, $white 0%, rgba(255, 255, 255, 0) 100%);
		opacity: 0;
		pointer-events: none;
		transition: opacity $long-trans ease;

		&.scrolled {
			opacity: 1;
		}
	}

	.offcanvas-body {
		--bs-offcanvas-padding-x: 0.875rem;
		--bs-offcanvas-padding-y: 1.25rem;

		padding-top: var(--bs-offcanvas-padding-y);
		position: relative;
		overflow-x: auto;
	}

	.sequence-wrapper {
		display: grid;
		grid-template-columns: repeat(10, 1fr);
		grid-template-rows: auto auto 101px auto auto 101px auto auto 101px auto auto 101px;
		gap: 0.25rem 0.25rem;
		grid-template-areas:
			"stage-1 stage-1 stage-1 stage-1 stage-1 stage-2 stage-2 stage-2 stage-2 stage-2"
			"s1-w1 s1-w2 s1-w3 s1-w4 s1-w5 s2-w1 s2-w2 s2-w3 s2-w4 s2-w5"
			"s1-w1-a s1-w2-a s1-w3-a s1-w4-a s1-w5-a s2-w1-a s2-w2-a s2-w3-a s2-w4-a s2-w5-a"
			"stage-3 stage-3 stage-3 stage-4 stage-4 stage-4 stage-4 stage-4 stage-plus4 stage-plus4"
			"s3-w1 s3-w2 s3-w3 s4-w1 s4-w2 s4-w3 s4-w4 s4-w5 s4plus-w1 s4plus-w2"
			"s3-w1-a s3-w2-a s3-w3-a s4-w1-a s4-w2-a s4-w3-a s4-w4-a s4-w4-a s4plus-w1-a s4plus-w1-a"
			"stage-plus4-2 stage-5 stage-5 stage-5 stage-5 stage-5 stage-6 stage-6 stage-6 stage-6"
			"s4plus-w3 s5-w1 s5-w2 s5-w3 s5-w4 s5-w5 s6-w1 s6-w2 s6-w3 s6-w4"
			"s4plus-w3-a s5-w1-a s5-w1-a s5-w1-a s5-w4-a s5-w5-a s6-w1-a s6-w2-a s6-w3-a s6-w4-a"
			"stage-6-2 stage-7 stage-7 stage-7 stage-7 stage-7 stage-7 stage-7 stage-7 stage-7"
			"s6-w5 s7-w1 s7-w2 s7-w3 s7-w4 s7-w5 s7-w6 s7-w7 s7-w8 s7-w9"
			"s6-w5-a s7-w1-a s7-w2-a s7-w3-a s7-w4-a s7-w5-a s7-w6-a s7-w7-a s7-w8-a s7-w8-a";

		&.year-1 {
			grid-template-areas:
				"revision revision stage-7-1 stage-7-1 stage-7-1 stage-7-1 stage-7-1 stage-7-1 stage-7-1 stage-7-1"
				"r-w1 r-w2 s7-1-w1 s7-1-w2 s7-1-w3 s7-1-w4 s7-1-w5 s7-1-w6 s7-1-w7 s7-1-w8"
				"r-w1-a r-w1-a s7-1-w1-a s7-1-w2-a s7-1-w3-a s7-1-w4-a s7-1-w5-a s7-1-w6-a s7-1-w7-a s7-1-w8-a"
				"stage-7-2 stage-7-2 stage-7-2 stage-7-2 stage-7-2 stage-7-2 stage-7-2 stage-7-3 stage-7-3 stage-7-3"
				"s7-2-w1 s7-2-w2 s7-2-w3 s7-2-w4 s7-2-w5 s7-2-w6 s7-2-w7 s7-3-w1 s7-3-w2 s7-3-w3"
				"s7-2-w1-a s7-2-w2-a s7-2-w3-a s7-2-w4-a s7-2-w5-a s7-2-w6-a s7-2-w6-a s7-3-w1-a s7-3-w1-a s7-3-w3-a"
				"stage-7-3-2 stage-7-3-2 stage-7-3-2 stage-7-4 stage-7-4 stage-7-4 stage-7-4 stage-7-4 stage-7-4 stage-7-4"
				"s7-3-w4 s7-3-w5 s7-3-w6 s7-4-w1 s7-4-w2 s7-4-w3 s7-4-w4 s7-4-w5 s7-4-w6 s7-4-w7"
				"s7-3-w4-a s7-3-w5-a s7-3-w6-a s7-4-w1-a s7-4-w1-a s7-4-w3-a s7-4-w3-a s7-4-w5-a s7-4-w6-a s7-4-w6-a"
				"stage-7-4-2 stage-7-4-2 stage-7-4-2 stage-7-4-2 stage-7-4-2 stage-7-4-2 stage-7-4-2 stage-7-4-2 stage-7-4-2 stage-7-4-2"
				"s7-4-w8 s7-4-w9 s7-4-w10 s7-4-w11 s7-4-w12 s7-4-w13 s7-4-w14 s7-4-w15 s7-4-w16 s7-4-w17"
				"s7-4-w8-a s7-4-w9-a s7-4-w9-a s7-4-w11-a s7-4-w11-a s7-4-w13-a s7-4-w13-a s7-4-w15-a s7-4-w15-a s7-4-w15-a";
		}

		&.year-2 {
			grid-template-rows:
				auto auto minmax(150px, auto) auto auto minmax(150px, auto) auto auto minmax(150px, auto)
				auto auto minmax(134px, auto);
			grid-template-areas:
				"stage-7 stage-7 stage-7 stage-7 stage-7 stage-7 stage-7 stage-7 stage-7 stage-7"
				"s7-w1 s7-w2 s7-w3 s7-w4 s7-w5 s7-w6 s7-w7 s7-w8 s7-w9 s7-w10"
				"s7-w1-a s7-w2-a s7-w3-a s7-w4-a s7-w5-a s7-w6-a s7-w6-a s7-w8-a s7-w9-a s7-w9-a"
				"stage-7-5 stage-7-5 stage-7-5 stage-7-5 stage-7-5 stage-7-5 stage-7-5 stage-7-5 stage-7-5 stage-7-5"
				"s7-5-w1 s7-5-w2 s7-5-w3 s7-5-w4 s7-5-w5 s7-5-w6 s7-5-w7 s7-5-w8 s7-5-w9 s7-5-w10"
				"s7-5-w1-a s7-5-w2-a s7-5-w3-a s7-5-w3-a s7-5-w5-a s7-5-w5-a s7-5-w7-a s7-5-w8-a s7-5-w9-a s7-5-w10-a"
				"stage-7-5-2 stage-7-5-2 stage-7-5-2 stage-7-5-2 stage-7-5-2 stage-7-5-2 stage-7-5-2 stage-7-5-2 stage-8 stage-8"
				"s7-5-2-w11 s7-5-2-w12 s7-5-2-w13 s7-5-2-w14 s7-5-2-w15 s7-5-2-w16 s7-5-2-w17 s7-5-2-w18 s8-w1 s8-w2"
				"s7-5-2-w11-a s7-5-2-w12-a s7-5-2-w13-a s7-5-2-w14-a s7-5-2-w15-a s7-5-2-w16-a s7-5-2-w17-a s7-5-2-w18-a s8-w1-a s8-w2-a"
				"stage-8-2 stage-8-2 stage-8-2 stage-8-2 stage-8-2 stage-8-2 stage-8-2 stage-8-2 stage-8-2 stage-8-2"
				"s8-2-w3 s8-2-w4 s8-2-w5 s8-2-w6 s8-2-w7 s8-2-w8 s8-2-w9 s8-2-w10 s8-2-w11 s8-2-w12"
				"s8-2-w3-a s8-2-w4-a s8-2-w5-a s8-2-w6-a s8-2-w7-a s8-2-w8-a s8-2-w9-a s8-2-w10-a s8-2-w11-a s8-2-w11-a";
		}

		.stacked-group {
			display: flex;
			flex-flow: column nowrap;
			gap: 0.25rem;
		}

		.phoneme-wrapper {
			display: flex;
			flex-flow: column nowrap;
			gap: 0.25rem;
		}
		.phoneme-icon {
			padding: 0.1875rem 0.25rem;
			background: $white;
			border-radius: 0.125rem;
			font-weight: 500;
			font-size: 0.875rem;
			font-family: $sassoon;
			font-weight: 500;
		}

		[class*="stage"],
		.revision {
			padding: 0.625rem 0;
			display: flex;
			justify-content: center;
			border-radius: 1.5rem 1.5rem 0 0;
			color: $white;
			margin-top: 0.25rem;
		}

		.stage-plus4,
		.stage-6,
		.stage-7-3,
		.stage-7-4,
		.stage-7-5,
		.stage-8 {
			border-top-right-radius: 0;
		}

		.stage-plus4-2,
		.stage-6-2,
		.stage-7-3-2,
		.stage-7-4-2,
		.stage-7-5-2,
		.stage-8-2 {
			border-top-left-radius: 0;
		}

		[class*="s1-w"],
		[class*="s2-w"],
		[class*="s3-w"],
		[class*="s4-w"],
		[class*="s4plus-w"],
		[class*="s5-w"],
		[class*="s6-w"],
		[class*="s7-w"],
		[class*="s7-1-w"],
		[class*="s7-2-w"],
		[class*="s7-3-w"],
		[class*="s7-4-w"],
		[class*="s7-5-w"],
		[class*="s7-5-2-w"],
		[class*="s8-w"],
		[class*="s8-2-w"],
		[class*="r-w"] {
			padding: 0.5rem 0.25rem;
			display: flex;
			justify-content: center;
			color: $white;
		}

		[class*="-a"] {
			padding: 0.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
			gap: 0.375rem 0.25rem; // design has some vert gaps at 8px, and some at 4px. We can't assign them based on content, so am setting 6px as an inbetween
			color: $primary;
			overflow: hidden;

			&:has(.revise) {
				padding: 0.25rem;
				flex-flow: column nowrap;
			}

			.attribute-wrapper {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				justify-content: center;
				gap: 0.25rem;
			}

			.attribute {
				display: flex;
				min-width: 2rem;
				min-height: 2rem;
				justify-content: center;
				align-items: center;
				gap: 0.25rem;
				border-radius: 0.375rem;
				border-top: 0.0625rem solid var(--stage-color);
				border-right: 0.0625rem solid var(--stage-color);
				border-bottom: 0.2rem solid var(--stage-color);
				border-left: 0.0625rem solid var(--stage-color);
				background: $white;
				font-family: $sassoon;
				font-size: 0.875rem;
				font-style: normal;
				font-weight: 500;
				line-height: 100%;
				padding: 0.5rem;

				&.orange {
					border-color: $stage7;
				}

				&.blank {
					border-color: transparent;
					background-color: transparent;
					min-width: auto;
					min-height: auto;
					padding: 0;
				}

				&.reading {
					opacity: 0.8;
				}

				&.phoneme {
					border-color: $stage6;

					&.teal {
						border-color: $alert-green;
					}
				}
			}

			.revise {
				background-color: rgba($color: $white, $alpha: 0.6);
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 0.375rem;
				text-align: center;
				overflow: hidden;

				&.revise-wrapper {
					background-color: transparent;
				}
			}
		}

		[class*="s1-w"] {
			color: $shades-800;

			&:hover,
			&:focus-visible {
				--bs-btn-focus-box-shadow: none;
				background-color: $stage1-dark;
			}

			&[class*="-a"] {
				--stage-color: #{$stage-1};

				background-color: $stage1-light;
			}

			&.s1-w1-a {
				border-bottom-left-radius: 0.5rem;
			}

			&.s1-w5-a {
				border-bottom-right-radius: 0.5rem;
			}
		}

		[class*="s2-w"] {
			&:hover,
			&:focus-visible {
				--bs-btn-focus-box-shadow: none;
				background-color: $stage2-dark;
			}

			&[class*="-a"] {
				--stage-color: #{$stage2};

				background-color: $stage2-light;
			}

			&.s2-w1-a {
				border-bottom-left-radius: 0.5rem;
			}

			&.s2-w5-a {
				border-bottom-right-radius: 0.5rem;
			}
		}

		[class*="s3-w"] {
			&:hover,
			&:focus-visible {
				--bs-btn-focus-box-shadow: none;
				background-color: $stage3-dark;
			}

			&[class*="-a"] {
				--stage-color: #{$stage3};

				background-color: $stage3-light;
			}

			&.s3-w1-a {
				border-bottom-left-radius: 0.5rem;
			}

			&.s3-w3-a {
				border-bottom-right-radius: 0.5rem;
			}
		}

		[class*="s4-w"] {
			&:hover,
			&:focus-visible {
				--bs-btn-focus-box-shadow: none;
				background-color: $stage4-dark;
			}

			&[class*="-a"] {
				--stage-color: #{$stage4};

				background-color: $stage4-light;
			}

			&.s4-w1-a {
				border-bottom-left-radius: 0.5rem;
			}

			&.s4-w4-a {
				border-bottom-right-radius: 0.5rem;
			}
		}

		[class*="s4plus-w"] {
			&:hover,
			&:focus-visible {
				--bs-btn-focus-box-shadow: none;
				background-color: $stage4-plus-dark;
			}

			&[class*="-a"] {
				--stage-color: #{$stage4-plus};

				background-color: $stage4-plus-light;
			}

			&.s4plus-w1-a {
				border-bottom-left-radius: 0.5rem;
			}

			&.s4plus-w3-a {
				border-bottom-right-radius: 0.5rem;
			}
		}

		[class*="s5-w"] {
			&:hover,
			&:focus-visible {
				--bs-btn-focus-box-shadow: none;
				background-color: $stage5-dark;
			}

			&[class*="-a"] {
				--stage-color: #{$stage5};

				background-color: $stage5-light;
			}

			&.s5-w1-a {
				border-bottom-left-radius: 0.5rem;
			}

			&.s5-w5-a {
				border-bottom-right-radius: 0.5rem;
			}
		}

		[class*="s6-w"] {
			&:hover,
			&:focus-visible {
				--bs-btn-focus-box-shadow: none;
				background-color: $stage6-dark;
			}

			&[class*="-a"] {
				--stage-color: #{$stage6};

				background-color: $stage6-light;
			}

			&.s6-w1-a {
				border-bottom-left-radius: 0.5rem;
			}

			&.s6-w5-a {
				border-bottom-right-radius: 0.5rem;
			}
		}

		[class*="s7-w"],
		[class*="s7-1-w"],
		[class*="s7-2-w"],
		[class*="s7-3-w"],
		[class*="s7-4-w"],
		[class*="s7-5-w"],
		[class*="s7-5-2-w"] {
			&:hover,
			&:focus-visible {
				--bs-btn-focus-box-shadow: none;
				background-color: $stage7-dark;
			}

			&[class*="-a"] {
				--stage-color: #{$stage7};

				background-color: $stage7-light;
			}

			&.s7-w1-a,
			&.s7-1-w1-a,
			&.s7-2-w1-a,
			&.s7-3-w1-a,
			&.s7-4-w1-a,
			&.s7-5-w1-a,
			&.s7-5-2-w11-a {
				border-bottom-left-radius: 0.5rem;
			}

			&.year-1 > .s7-w8-a,
			&.s7-1-w8-a,
			&.s7-2-w6-a,
			&.s7-3-w6-a,
			&.s7-4-w15-a,
			&.s7-w9-a,
			&.s7-5-w10-a,
			&.s7-5-2-w18-a {
				border-bottom-right-radius: 0.5rem;
			}
		}

		[class*="s8-w"],
		[class*="s8-2-w"] {
			&:hover,
			&:focus-visible {
				--bs-btn-focus-box-shadow: none;
				background-color: $stage8-dark;
			}

			&[class*="-a"] {
				--stage-color: #{$stage8};

				background-color: $stage8-light;
			}

			&.s8-w1-a,
			&.s8-2-w3-a {
				border-bottom-left-radius: 0.5rem;
			}

			&.s8-w5-a,
			&.s8-2-w11-a {
				border-bottom-right-radius: 0.5rem;
			}
		}

		[class*="r-w"] {
			background-color: $milo-blue;

			&:hover,
			&:focus-visible {
				--bs-btn-focus-box-shadow: none;
				background-color: $vivid-blue;
			}

			&[class*="-a"] {
				--stage-color: #{$milo-blue};

				background-color: $b1-pale;
			}

			&.r-w1-a {
				border-bottom-left-radius: 0.5rem;
				border-bottom-right-radius: 0.5rem;
			}
		}

		.btn {
			--bs-btn-border-radius: 0;
		}

		[class*="stage-7"] {
			grid-area: stage-7;
			background-color: $stage7;
		}

		[class*="s1"] {
			background-color: $stage-1;
		}

		[class*="s2"] {
			background-color: $stage2;
		}

		[class*="s3"] {
			background-color: $stage3;
		}

		[class*="s4"] {
			background-color: $stage4;
		}

		[class*="s4plus"] {
			background-color: $stage4-plus;
		}

		[class*="s5"] {
			background-color: $stage5;
		}

		[class*="s6"] {
			background-color: $stage6;
		}

		[class*="s7"] {
			background-color: $stage7;
		}

		[class*="s8"] {
			background-color: $stage8;
		}

		.stage-1,
		.stage-2,
		.revision,
		.stage-7-1 {
			margin-top: 0;
		}

		.stage-1 {
			grid-area: stage-1;
			background-color: $stage-1;
			color: $shades-800;
		}

		.stage-2 {
			grid-area: stage-2;
			background-color: $stage2;
		}

		.stage-3 {
			grid-area: stage-3;
			background-color: $stage3;
		}

		.stage-4 {
			grid-area: stage-4;
			background-color: $stage4;
		}

		.stage-plus4 {
			grid-area: stage-plus4;
			background-color: $stage4-plus;
		}

		.stage-plus4-2 {
			grid-area: stage-plus4-2;
			background-color: $stage4-plus;
		}

		.stage-5 {
			grid-area: stage-5;
			background-color: $stage5;
		}

		.stage-6 {
			grid-area: stage-6;
			background-color: $stage6;
		}

		.stage-6-2 {
			grid-area: stage-6-2;
			background-color: $stage6;
		}

		.stage-7 {
			grid-area: stage-7;
			background-color: $stage7;
		}

		.stage-7-5 {
			grid-area: stage-7-5;
			background-color: $stage7;
		}

		.stage-7-5-2 {
			grid-area: stage-7-5-2;
			background-color: $stage7;
		}

		.stage-8 {
			grid-area: stage-8;
			background-color: $stage8;
		}

		.stage-8-2 {
			grid-area: stage-8-2;
			background-color: $stage8;
		}

		.revision {
			grid-area: revision;
			background-color: $milo-blue;
		}

		.s1-w1 {
			grid-area: s1-w1;
		}

		.s1-w2 {
			grid-area: s1-w2;
		}

		.s1-w3 {
			grid-area: s1-w3;
		}

		.s1-w4 {
			grid-area: s1-w4;
		}

		.s1-w5 {
			grid-area: s1-w5;
		}

		.s2-w1 {
			grid-area: s2-w1;
		}

		.s2-w2 {
			grid-area: s2-w2;
		}

		.s2-w3 {
			grid-area: s2-w3;
		}

		.s2-w4 {
			grid-area: s2-w4;
		}

		.s2-w5 {
			grid-area: s2-w5;
		}

		.s3-w1 {
			grid-area: s3-w1;
		}

		.s3-w2 {
			grid-area: s3-w2;
		}

		.s3-w3 {
			grid-area: s3-w3;
		}

		.s3-w4 {
			grid-area: s3-w4;
		}

		.s4-w1 {
			grid-area: s4-w1;
		}

		.s4-w2 {
			grid-area: s4-w2;
		}

		.s4-w3 {
			grid-area: s4-w3;
		}

		.s4-w4 {
			grid-area: s4-w4;
		}

		.s4-w5 {
			grid-area: s4-w5;
		}

		.s4plus-w1 {
			grid-area: s4plus-w1;
		}

		.s4plus-w2 {
			grid-area: s4plus-w2;
		}

		.s4plus-w3 {
			grid-area: s4plus-w3;
		}

		.s5-w1 {
			grid-area: s5-w1;
		}

		.s5-w2 {
			grid-area: s5-w2;
		}

		.s5-w3 {
			grid-area: s5-w3;
		}

		.s5-w4 {
			grid-area: s5-w4;
		}

		.s5-w5 {
			grid-area: s5-w5;
		}

		.s6-w1 {
			grid-area: s6-w1;
		}

		.s6-w2 {
			grid-area: s6-w2;
		}

		.s6-w3 {
			grid-area: s6-w3;
		}

		.s6-w4 {
			grid-area: s6-w4;
		}

		.s6-w5 {
			grid-area: s6-w5;
		}

		.s7-w1 {
			grid-area: s7-w1;
		}

		.s7-w2 {
			grid-area: s7-w2;
		}

		.s7-w3 {
			grid-area: s7-w3;
		}

		.s7-w4 {
			grid-area: s7-w4;
		}

		.s7-w5 {
			grid-area: s7-w5;
		}

		.s7-w6 {
			grid-area: s7-w6;
		}

		.s7-w7 {
			grid-area: s7-w7;
		}

		.s7-w8 {
			grid-area: s7-w8;
		}

		.s7-w9 {
			grid-area: s7-w9;
		}

		.s7-w10 {
			grid-area: s7-w10;
		}

		.s7-5-w1 {
			grid-area: s7-5-w1;
		}
		.s7-5-w2 {
			grid-area: s7-5-w2;
		}
		.s7-5-w3 {
			grid-area: s7-5-w3;
		}
		.s7-5-w4 {
			grid-area: s7-5-w4;
		}
		.s7-5-w5 {
			grid-area: s7-5-w5;
		}
		.s7-5-w6 {
			grid-area: s7-5-w6;
		}
		.s7-5-w7 {
			grid-area: s7-5-w7;
		}
		.s7-5-w8 {
			grid-area: s7-5-w8;
		}
		.s7-5-w9 {
			grid-area: s7-5-w9;
		}
		.s7-5-w10 {
			grid-area: s7-5-w10;
		}

		.s7-5-2-w11 {
			grid-area: s7-5-2-w11;
		}
		.s7-5-2-w12 {
			grid-area: s7-5-2-w12;
		}
		.s7-5-2-w13 {
			grid-area: s7-5-2-w13;
		}
		.s7-5-2-w14 {
			grid-area: s7-5-2-w14;
		}
		.s7-5-2-w15 {
			grid-area: s7-5-2-w15;
		}
		.s7-5-2-w16 {
			grid-area: s7-5-2-w16;
		}
		.s7-5-2-w17 {
			grid-area: s7-5-2-w17;
		}
		.s7-5-2-w18 {
			grid-area: s7-5-2-w18;
		}

		.s8-w1 {
			grid-area: s8-w1;
		}
		.s8-w2 {
			grid-area: s8-w2;
		}

		.s8-2-w3 {
			grid-area: s8-2-w3;
		}
		.s8-2-w4 {
			grid-area: s8-2-w4;
		}
		.s8-2-w5 {
			grid-area: s8-2-w5;
		}
		.s8-2-w6 {
			grid-area: s8-2-w6;
		}
		.s8-2-w7 {
			grid-area: s8-2-w7;
		}
		.s8-2-w8 {
			grid-area: s8-2-w8;
		}
		.s8-2-w9 {
			grid-area: s8-2-w9;
		}
		.s8-2-w10 {
			grid-area: s8-2-w10;
		}
		.s8-2-w11 {
			grid-area: s8-2-w11;
		}
		.s8-2-w12 {
			grid-area: s8-2-w12;
		}

		.s1-w2-a {
			grid-area: s1-w2-a;
		}

		.s1-w1-a {
			grid-area: s1-w1-a;
		}

		.s1-w3-a {
			grid-area: s1-w3-a;
		}

		.s1-w4-a {
			grid-area: s1-w4-a;
		}

		.s1-w5-a {
			grid-area: s1-w5-a;
		}

		.s2-w1-a {
			grid-area: s2-w1-a;
		}

		.s2-w2-a {
			grid-area: s2-w2-a;
		}

		.s2-w3-a {
			grid-area: s2-w3-a;
		}

		.s2-w4-a {
			grid-area: s2-w4-a;
		}

		.s2-w5-a {
			grid-area: s2-w5-a;
		}

		.s3-w1-a {
			grid-area: s3-w1-a;
		}

		.s3-w2-a {
			grid-area: s3-w2-a;
		}

		.s3-w3-a {
			grid-area: s3-w3-a;
		}

		.s4-w1-a {
			grid-area: s4-w1-a;
		}

		.s4-w2-a {
			grid-area: s4-w2-a;
		}

		.s4-w3-a {
			grid-area: s4-w3-a;
		}

		.s4-w4-a {
			grid-area: s4-w4-a;
		}

		.s4-w5-a {
			grid-area: s4-w5-a;
		}

		.s4plus-w1-a {
			grid-area: s4plus-w1-a;
		}

		.s4plus-w2-a {
			grid-area: s4plus-w2-a;
		}

		.s4plus-w3-a {
			grid-area: s4plus-w3-a;
		}

		.s5-w1-a {
			grid-area: s5-w1-a;
		}

		.s5-w2-a {
			grid-area: s5-w2-a;
		}

		.s5-w3-a {
			grid-area: s5-w3-a;
		}

		.s5-w4-a {
			grid-area: s5-w4-a;
		}

		.s5-w5-a {
			grid-area: s5-w5-a;
		}

		.s6-w1-a {
			grid-area: s6-w1-a;
		}

		.s6-w2-a {
			grid-area: s6-w2-a;
		}

		.s6-w3-a {
			grid-area: s6-w3-a;
		}

		.s6-w4-a {
			grid-area: s6-w4-a;
		}

		.s6-w5-a {
			grid-area: s6-w5-a;
		}

		.s7-w1-a {
			grid-area: s7-w1-a;
		}

		.s7-w2-a {
			grid-area: s7-w2-a;
		}

		.s7-w3-a {
			grid-area: s7-w3-a;
		}

		.s7-w4-a {
			grid-area: s7-w4-a;
		}

		.s7-w5-a {
			grid-area: s7-w5-a;
		}

		.s7-w6-a {
			grid-area: s7-w6-a;
		}

		.s7-w7-a {
			grid-area: s7-w7-a;
		}

		.s7-w8-a {
			grid-area: s7-w8-a;
		}

		.s7-w9-a {
			grid-area: s7-w9-a;
		}

		.s7-w10-a {
			grid-area: s7-w10-a;
		}

		.s7-5-w1-a {
			grid-area: s7-5-w1-a;
		}
		.s7-5-w2-a {
			grid-area: s7-5-w2-a;
		}
		.s7-5-w3-a {
			grid-area: s7-5-w3-a;
		}
		.s7-5-w5-a {
			grid-area: s7-5-w5-a;
		}
		.s7-5-w7-a {
			grid-area: s7-5-w7-a;
		}
		.s7-5-w8-a {
			grid-area: s7-5-w8-a;
		}
		.s7-5-w9-a {
			grid-area: s7-5-w9-a;
		}
		.s7-5-w10-a {
			grid-area: s7-5-w10-a;
		}

		.s7-5-2-w11-a {
			grid-area: s7-5-2-w11-a;
		}
		.s7-5-2-w12-a {
			grid-area: s7-5-2-w12-a;
		}
		.s7-5-2-w13-a {
			grid-area: s7-5-2-w13-a;
		}
		.s7-5-2-w14-a {
			grid-area: s7-5-2-w14-a;
		}
		.s7-5-2-w15-a {
			grid-area: s7-5-2-w15-a;
		}
		.s7-5-2-w16-a {
			grid-area: s7-5-2-w16-a;
		}
		.s7-5-2-w17-a {
			grid-area: s7-5-2-w17-a;
		}
		.s7-5-2-w18-a {
			grid-area: s7-5-2-w18-a;
		}

		.s8-w1-a {
			grid-area: s8-w1-a;
		}
		.s8-w2-a {
			grid-area: s8-w2-a;
		}

		.s8-2-w3-a {
			grid-area: s8-2-w3-a;
		}
		.s8-2-w4-a {
			grid-area: s8-2-w4-a;
		}
		.s8-2-w5-a {
			grid-area: s8-2-w5-a;
		}
		.s8-2-w6-a {
			grid-area: s8-2-w6-a;
		}
		.s8-2-w7-a {
			grid-area: s8-2-w7-a;
		}
		.s8-2-w8-a {
			grid-area: s8-2-w8-a;
		}
		.s8-2-w9-a {
			grid-area: s8-2-w9-a;
		}
		.s8-2-w10-a {
			grid-area: s8-2-w10-a;
		}
		.s8-2-w11-a {
			grid-area: s8-2-w11-a;
		}

		.r-w1 {
			grid-area: r-w1;
		}

		.r-w2 {
			grid-area: r-w2;
		}

		.r-w1-a {
			grid-area: r-w1-a;
		}

		.stage-7-1 {
			grid-area: stage-7-1;
		}

		.s7-1-w1 {
			grid-area: s7-1-w1;
		}

		.s7-1-w1-a {
			grid-area: s7-1-w1-a;
		}

		.s7-1-w2 {
			grid-area: s7-1-w2;
		}

		.s7-1-w2-a {
			grid-area: s7-1-w2-a;
		}

		.s7-1-w3 {
			grid-area: s7-1-w3;
		}

		.s7-1-w3-a {
			grid-area: s7-1-w3-a;
		}

		.s7-1-w4 {
			grid-area: s7-1-w4;
		}

		.s7-1-w4-a {
			grid-area: s7-1-w4-a;
		}

		.s7-1-w5 {
			grid-area: s7-1-w5;
		}

		.s7-1-w5-a {
			grid-area: s7-1-w5-a;
		}

		.s7-1-w6 {
			grid-area: s7-1-w6;
		}

		.s7-1-w6-a {
			grid-area: s7-1-w6-a;
		}

		.s7-1-w7 {
			grid-area: s7-1-w7;
		}

		.s7-1-w7-a {
			grid-area: s7-1-w7-a;
		}

		.s7-1-w8 {
			grid-area: s7-1-w8;
		}

		.s7-1-w8-a {
			grid-area: s7-1-w8-a;
		}

		.stage-7-2 {
			grid-area: stage-7-2;
		}

		.stage-7-3 {
			grid-area: stage-7-3;
		}

		.stage-7-3-2 {
			grid-area: stage-7-3-2;
		}

		.stage-7-4 {
			grid-area: stage-7-4;
		}

		.stage-7-4-2 {
			grid-area: stage-7-4-2;
		}

		.s7-2-w1 {
			grid-area: s7-2-w1;
		}

		.s7-2-w2 {
			grid-area: s7-2-w2;
		}

		.s7-2-w3 {
			grid-area: s7-2-w3;
		}

		.s7-2-w4 {
			grid-area: s7-2-w4;
		}

		.s7-2-w5 {
			grid-area: s7-2-w5;
		}

		.s7-2-w6 {
			grid-area: s7-2-w6;
		}

		.s7-2-w7 {
			grid-area: s7-2-w7;
		}

		.s7-3-w1 {
			grid-area: s7-3-w1;
		}

		.s7-3-w2 {
			grid-area: s7-3-w2;
		}

		.s7-3-w3 {
			grid-area: s7-3-w3;
		}

		.s7-2-w6-a {
			grid-area: s7-2-w6-a;
		}

		.s7-3-w1-a {
			grid-area: s7-3-w1-a;
		}

		.s7-3-w3-a {
			grid-area: s7-3-w3-a;
		}

		.s7-2-w1-a {
			grid-area: s7-2-w1-a;
		}

		.s7-2-w2-a {
			grid-area: s7-2-w2-a;
		}

		.s7-2-w3-a {
			grid-area: s7-2-w3-a;
		}

		.s7-2-w4-a {
			grid-area: s7-2-w4-a;
		}

		.s7-2-w5-a {
			grid-area: s7-2-w5-a;
		}

		.s7-3-w4 {
			grid-area: s7-3-w4;
		}

		.s7-3-w5 {
			grid-area: s7-3-w5;
		}

		.s7-3-w6 {
			grid-area: s7-3-w6;
		}

		.s7-3-w4-a {
			grid-area: s7-3-w4-a;
		}

		.s7-3-w5-a {
			grid-area: s7-3-w5-a;
		}

		.s7-3-w6-a {
			grid-area: s7-3-w6-a;
		}

		.s7-4-w1 {
			grid-area: s7-4-w1;
		}

		.s7-4-w2 {
			grid-area: s7-4-w2;
		}

		.s7-4-w3 {
			grid-area: s7-4-w3;
		}

		.s7-4-w4 {
			grid-area: s7-4-w4;
		}

		.s7-4-w5 {
			grid-area: s7-4-w5;
		}

		.s7-4-w6 {
			grid-area: s7-4-w6;
		}

		.s7-4-w7 {
			grid-area: s7-4-w7;
		}

		.s7-4-w1-a {
			grid-area: s7-4-w1-a;
		}

		.s7-4-w3-a {
			grid-area: s7-4-w3-a;
		}

		.s7-4-w5-a {
			grid-area: s7-4-w5-a;
		}

		.s7-4-w6-a {
			grid-area: s7-4-w6-a;
		}

		.s7-4-w8 {
			grid-area: s7-4-w8;
		}

		.s7-4-w8-a {
			grid-area: s7-4-w8-a;
		}

		.s7-4-w9 {
			grid-area: s7-4-w9;
		}

		.s7-4-w10 {
			grid-area: s7-4-w10;
		}

		.s7-4-w11 {
			grid-area: s7-4-w11;
		}

		.s7-4-w12 {
			grid-area: s7-4-w12;
		}

		.s7-4-w13 {
			grid-area: s7-4-w13;
		}

		.s7-4-w14 {
			grid-area: s7-4-w14;
		}

		.s7-4-w15 {
			grid-area: s7-4-w15;
		}

		.s7-4-w16 {
			grid-area: s7-4-w16;
		}

		.s7-4-w17 {
			grid-area: s7-4-w17;
		}

		.s7-4-w9-a {
			grid-area: s7-4-w9-a;
		}

		.s7-4-w11-a {
			grid-area: s7-4-w11-a;
		}

		.s7-4-w13-a {
			grid-area: s7-4-w13-a;
		}

		.s7-4-w15-a {
			grid-area: s7-4-w15-a;
		}
	}
}
