.selected-items-wrapper {
	--col-gap: 1.25rem;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	row-gap: 0.5rem;
	column-gap: var(--col-gap);
	padding: 0.75rem 0.75rem 0.75rem 1.5rem;
	background-color: $b1-pale;
	border-radius: 0.75rem;
	color: $light-blue;

	.btn-link + .btn-link {
		margin-left: var(--col-gap);

		// ::before is already used by the hyperlink psuedo...
		&::after {
			content: "";
			position: absolute;
			top: 0;
			right: calc(100% + var(--col-gap));
			width: 1px;
			height: 100%;
			background-color: $light-blue;
		}
	}
}

.class-details-page {
	.empty-wrapper {
		padding: 3.75rem 1.5rem;
		background-color: $shades-100;
		border-radius: 0.75rem;
		border: 0.0625rem solid $shades-400;
		color: $shades-500;
	}

	.student-list-wrapper {
		.student-list-title,
		.student-list-item {
			--grid-gutter-width: 0;
		}

		.student-list-title {
			flex-flow: row nowrap;
			min-height: 5rem;
			padding: 0.5rem 1.5rem;
			align-items: center;
			border: 0.0625rem solid $shades-400;
			background-color: $shades-100;
			border-radius: 1.25rem 1.25rem 0 0;
		}

		.student-list-content {
			.student-list-item {
				border: 0.0625rem solid $shades-400;
				border-top: 0;
				min-height: 5rem;
				background-color: $white;
				padding: 1rem 1.5rem;

				&:last-child {
					border-radius: 0 0 1.25rem 1.25rem;
				}

				.actions-dropdown {
					right: 0;
				}
			}
		}
	}
}
