:root {
	--brand-primary: #{$primary};
	--brand-secondary: #{$secondary};
	--brand-tertiary: #{$tertiary};
	--rgb-brand-primary: #{$primary-rgb};
	--rgb-brand-secondary: #{$secondary-rgb};
	--rgb-brand-tertiary: #{$tertiary-rgb};

	--bs-link-color-rgb: var(--rgb-brand-tertiary);
	// --bs-link-hover-color-rgb: var(--rgb-component-accent1-color);
	--bs-link-hover-color-rgb: var(--rgb-brand-tertiary);

	--container-gutter-width: #{$container-gutter-width-xs};
	--grid-gutter-width: #{$grid-gutter-width-xs};
	--p-spacing: 1.25rem;
	--base-font-size: #{$base-font-size};
	--base-line-height: #{$base-line-height};

	--progress-variable: 0;
	--corner-btn-width: 7.5rem;

	@include media-breakpoint-up(sm) {
		--container-gutter-width: #{$container-gutter-width-sm};
		--grid-gutter-width: #{$grid-gutter-width-sm};
	}

	@include media-breakpoint-up(md) {
		--container-gutter-width: #{$container-gutter-width-md};
		--grid-gutter-width: #{$grid-gutter-width-md};
	}

	@include media-breakpoint-up(lg) {
		--container-gutter-width: #{$container-gutter-width-lg};
		--grid-gutter-width: #{$grid-gutter-width-lg};
		--base-font-size: 16px;
	}

	@include media-breakpoint-up(xl) {
		--container-gutter-width: #{$container-gutter-width-xl};
		--grid-gutter-width: #{$grid-gutter-width-xl};
	}

	// including grid breakpoints from Bootstrap 4 (Bootstrap 5 doesn't include them for some reason)
	@each $name, $value in $grid-breakpoints {
		--#{$prefix}breakpoint-#{$name}: #{$value};
	}
}
