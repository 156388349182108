.offcanvas {
	box-shadow: 0 0.25rem 1.5rem 0 rgba($vivid-blue-tinted, 0.2);

	.offcanvas-header {
		padding-bottom: 2.5rem;

		.btn-close {
			--bs-btn-close-opacity: 1;
			--bs-btn-close-hover-opacity: 1;
			--bs-btn-close-bg: url("../../../../public/svg/cross-white-thin.svg");

			padding: 0;
			background-color: $vivid-blue;
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
			width: 3rem;
			height: 3rem;
			background-size: 1.5rem;
			margin: 0;
			transition: background-color $reg-trans ease;

			&:hover,
			&:focus-visible {
				background-color: $primary;
			}
		}
	}

	.offcanvas-body {
		display: flex;
		flex-flow: column nowrap;
		padding-top: 0;
		padding-bottom: 2.5rem;

		.form-selection-wrapper {
			background: $aura-blue;
		}
	}
}
