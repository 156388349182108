.add-class-page {
	.select-existing-class {
		background-color: $white;
		border: 0.0625rem solid $shades-400;
		border-radius: 1.25rem;

		.header {
			min-height: 5rem;
			padding: 0.5rem 1.5rem;
			background-color: $shades-100;
			border-bottom: 0.0625rem solid $shades-400;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			border-radius: 1.25rem 1.25rem 0 0;
		}

		.content {
			overflow-y: auto;
			overflow-x: hidden;
			min-height: 16.25rem;

			.no-class-icon {
				width: 6rem;
				height: 6rem;
				padding: 1.25rem;
				border: 0.0625rem dashed $shades-400;
				border-radius: 1.25rem;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $shades-400;
			}

			.icon-wrapper {
				background-color: $b1-pale;
				width: 3rem;
				height: 3rem;
				border-radius: 0.75rem;
				padding: 0.75rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.class-item {
				&:not(:first-child) {
					border-top: 0.0625rem solid $shades-200;
				}
			}
		}
	}
}
