// sass-lint:disable indentation
$font-path: "./../../../../public/fonts";

@font-face {
	font-family: "Quicksand";
	src: url("#{$font-path}/Quicksand-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Quicksand";
	src: url("#{$font-path}/Quicksand-SemiBold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "Quicksand";
	src: url("#{$font-path}/Quicksand-Bold.ttf") format("truetype");
	font-weight: 700;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: "SassoonInfant";
	src: url("#{$font-path}/SassoonInfant-Medium-BaselineAdjusted-Descent200.woff2") format("woff2");
	font-weight: 500;
	font-style: normal;
	font-display: block;
}
