.archive-student-page {
	.empty-wrapper {
		padding: 3.75rem 1.5rem;
		background-color: $shades-100;
		border-radius: 0.75rem;
		border: 0.0625rem solid $shades-400;
		color: $shades-500;
	}

	.student-list-wrapper {
		.student-list-title {
			flex-flow: row nowrap;
			height: 5rem;
			padding: 0.5rem 1.5rem;
			align-items: center;
			border: 0.0625rem solid $shades-400;
			background-color: $shades-100;
			border-radius: 1.25rem 1.25rem 0 0;
		}

		.student-list-content {
			.student-list-item {
				border: 0.0625rem solid $shades-400;
				border-top: 0;
				height: 82px;
				background-color: $white;
				padding: 1rem 1.5rem;

				&:last-child {
					border-radius: 0 0 1.25rem 1.25rem;
				}

				.actions-dropdown {
					right: 0;
				}
			}
		}
	}
}
