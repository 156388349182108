.multiple-pill-input-wrapper {
	background-color: $white;
	border: 0.0625rem solid $shades-400;
	border-radius: 0.5rem;
	display: flex;
	flex-flow: column nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	gap: 0.25rem;
	height: 17.5rem;
	overflow: hidden;
	user-select: none;
	transition-property: border-color, box-shadow;
	transition-duration: $reg-trans;
	transition-timing-function: ease;

	&:not(.error):has(.multiple-pill-input:focus) {
		border-color: $hero-blue;
		box-shadow: 0 0 0 0.125rem rgba(0, 183, 241, 0.2);
	}

	&.error {
		border-color: $alert-red;
		box-shadow: 0 0 0 0.125rem rgba(237, 17, 101, 0.2);
	}

	.emails-container {
		display: flex;
		flex-flow: row wrap;
		gap: 0.25rem;
		width: 100%;
		padding: 0.75rem;
		height: auto;
		overflow-y: auto;
	}

	.multiple-pill-input {
		border: 0;
		width: auto;
		height: auto;
		resize: none;
		overflow: hidden;
		flex-grow: 1;

		&:focus {
			box-shadow: none;
			outline: 0;
			border: 0;
		}
	}
}
