.add-student-page {
	.student-list {
		background-color: $white;
		border: 0.0625rem solid $shades-400;
		border-radius: 1.25rem;
		overflow: hidden;

		.header {
			min-height: 5rem;
			padding: 0.5rem 1.5rem;
			background-color: $shades-100;
			border-bottom: 0.0625rem solid $shades-400;
			display: flex;
			align-items: center;
			justify-content: space-between;
			border-radius: 1.25rem 1.25rem 0 0;
		}

		.content {
			overflow-y: auto;
			overflow-x: hidden;
			min-height: 16.25rem;

			.no-students-icon {
				width: 3rem;
				height: 3rem;
				padding: 1.25rem;
				border: 0.0625rem dashed $shades-500;
				border-radius: 0.75rem;
				display: flex;
				align-items: center;
				justify-content: center;
				color: $shades-500;
			}

			.sub-title {
				padding: 0.75rem;
				margin-bottom: 0;
				border-top: 0.0625rem solid $shades-200;
			}

			.icon-wrapper {
				background-color: $b1-pale;
				width: 3rem;
				height: 3rem;
				border-radius: 0.75rem;
				padding: 0.75rem;
				display: flex;
				align-items: center;
				justify-content: center;
			}

			.class-item {
				&:not(:first-child) {
					border-top: 0.0625rem solid $shades-200;
				}
			}
		}
	}
}
