.page-professional-learning {
	.category-section {
		gap: 1.25rem;
	}

	.category-title {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}

	.row-learning-items {
		row-gap: 1.25rem;
	}

	.btn-learning-item {
		--bs-btn-bg: #{$white};
		--bs-btn-color: #{$primary};
		--bs-btn-border-color: #{$shades-400};

		--bs-btn-hover-bg: var(--bs-btn-bg);
		--bs-btn-hover-color: #{$tertiary};
		--bs-btn-hover-border-color: #{$hero-blue};

		--bs-btn-padding-x: 1.25rem;
		--bs-btn-padding-y: 1.25rem;
		--thumbnail-bg-color: #{$stage4-light};

		text-align: start;
		display: flex;
		justify-content: flex-start;
		gap: 1.25rem;

		&:hover,
		&:focus-visible {
			box-shadow: var(--bs-btn-focus-box-shadow);

			.title {
				text-decoration-color: currentColor;
			}
		}

		&::before {
			display: none;
		}

		.learning-item-thumbnail {
			position: relative;
			background-color: var(--thumbnail-bg-color);
			width: 6.25rem;
			height: 6.25rem;
			border-radius: 0.625rem;

			.label-tag {
				color: $white;
				background-color: $vivid-blue;
				border: 0;
				right: 0.125rem;
				bottom: 0.125rem;
				border-radius: 0.625rem;
			}
		}

		.title {
			text-decoration: underline;
			text-decoration-color: transparent;
			transition: color $reg-trans ease, text-decoration-color $long-trans ease;
		}
	}
}
