.form-rounded-corners {
	--form-padding-x: 5rem;
	display: flex;
	flex-direction: column;
	gap: 2.5rem;
	width: 100%;
	max-width: 37.5rem;
	margin: 0 auto;
	padding: 3.75rem var(--form-padding-x) 5rem;
	border: 0.0625rem solid $shades-200;
	border-radius: 3rem;
	background-color: $white;
	text-align: center;
	box-shadow: 0 0.25rem 1.5rem 0 rgba(0, 183, 241, 0.2);

	h2 {
		text-wrap: pretty;
	}

	.bottom-bar {
		width: calc(100% + (var(--form-padding-x) * 2));
		margin-left: calc(var(--form-padding-x) * -1);
		padding: 2rem;
	}
}

.form-label {
	display: flex;
	justify-content: space-between;
	font-size: 0.875rem;
	font-weight: 700;
}

.form-control,
.form-select {
	font-size: $input-btn-font-size; // skipping fancy-pants Bootstrap conversion that resolves this font-size based on a calc and vw via the '@include font-size()' mixin in form-control.scss

	&:disabled {
		background-color: $shades-100;
	}
}

.form-check-input {
	margin-top: 0;
}

.form-group {
	.input-group {
		border-radius: var(--bs-border-radius);

		&:has(.form-control:focus) {
			box-shadow: $input-btn-focus-box-shadow;

			.input-group-text {
				border-color: $input-focus-border-color;
			}
		}
	}

	.form-control {
		&:focus {
			box-shadow: none;
		}
	}

	&.has-error {
		.input-group {
			box-shadow: 0 0 0 0.125rem rgba($error, 0.2);
		}

		.form-control,
		.input-group-text {
			--bs-border-color: #{$error};
		}

		.btn-select {
			--bs-btn-border-color: #{$error};
			--bs-btn-disabled-border-color: #{$error};
		}
	}
}

.field-filter {
	padding-left: 2.875rem;
	background: url(./../../../../public/svg/search-grey.svg) left 0.75rem center / 1.5rem no-repeat $white;

	&:focus,
	&:not(:placeholder-shown) {
		background-image: url(./../../../../public/svg/search-blue.svg);
	}

	&:not(:placeholder-shown) {
		--bs-border-color: #{$hero-blue};
	}
}

.react-switch {
	// overwriting some of React-Switch plugin styles here, since passing in styles via Bootstrap vars doesn't work, and it always expects hex codes as its colour properties
	.react-switch-handle {
		border: 0.0625rem solid rgba($white, 0.6) !important;
		box-shadow: 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);
	}

	// the plugin doesn't add a class when checked/unchecked...
	&:has(input[aria-checked="true"]) {
		.react-switch-handle {
			border-color: rgba($vivid-blue-tinted, 0.2) !important;
		}
	}

	&:has(input:focus-visible) {
		.react-switch-handle {
			box-shadow: 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2), $input-btn-focus-box-shadow !important;
		}
	}
}

input[type="checkbox"],
input[type="radio"] {
	flex-shrink: 0;
	width: 1.5rem;
	height: 1.5rem;
	appearance: none;
	cursor: pointer;
}

input[type="checkbox"] {
	border-radius: 0.25rem;
	border: 0.0625rem solid $shades-500;
	padding: 0;
	background: $white;

	&:hover {
		border: 0.0625rem solid $hero-blue;
		outline: 0;
		background: $b1-pale;
		box-shadow: 0 0 0 0.125rem rgba(0, 183, 241, 0.2);
	}

	&:checked {
		border: 0.0625rem solid $vivid-blue;
		background: $vivid-blue;
		box-shadow: none;
		display: flex;
		justify-content: center;
		align-items: center;

		&::after {
			@extend %svg-mask;

			--svg-mask-image: url("../../../../public/svg/check.svg");

			content: "";
			color: $white;
			width: 1rem;
			height: 1rem;
			display: block;
		}
	}

	&.deselect {
		&:checked {
			&::after {
				--svg-mask-image: url("../../../../public/svg/minus.svg");
			}
		}
	}
}

.search-input {
	background-repeat: no-repeat;
	background-position: 0.75rem center;
	background-size: 1.5rem 1.5rem;
	background-image: url("./../../../../public/svg/search-grey.svg");
	padding: 0.75rem 0.75rem 0.75rem 2.75rem;
	border-radius: 0.75rem;
	min-width: 18.625rem;
}

label.form-control-checkbox,
.form-control-checkbox {
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 0 0 0 35px;
	margin: 14px 0 15px;
	cursor: pointer;

	[type="checkbox"] {
		margin-left: -35px;
		margin-right: 8px;
	}
}

.radio-row {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 20px;

	.radio-option {
		font-size: 18px;
		font-weight: 700;
		margin-left: 12px;
		margin-bottom: 0;
	}

	&.align-start {
		align-items: flex-start;
	}
}

.form-selection-wrapper {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	gap: 0.25rem;
	margin-bottom: 2.5rem;
	border-radius: 0.75rem;
	border: 0.0625rem solid $shades-400;
	background-color: $aura-blue;
	overflow: hidden;

	.form-selection-wrapper-inner {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		gap: 0.25rem;
		padding: 0.5rem;
		overflow-y: auto;
		overflow-x: hidden;
	}

	.selected-icon-wrapper {
		width: 1rem;
		height: 1rem;
	}
}
