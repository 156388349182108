@use "sass:math";

.page-buyer-journey-home {
	.img-landing-page-header-wrapper,
	.img-screenshot-wrapper {
		position: relative;

		&::before {
			content: "";
			display: block;
		}

		img {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}
	}

	.img-landing-page-header-wrapper {
		&::before {
			padding-bottom: percentage(math.div(236, 1680));
			min-height: 144px;
		}
	}

	.img-screenshot-wrapper {
		&::before {
			padding-bottom: percentage(math.div(381, 721));
		}

		img {
			object-position: bottom right;
		}
	}

	.icon {
		@include media-breakpoint-up(md) {
			width: 160px;
			height: 160px;
		}
	}
}
