.quiz-matchin-with-lines {
	.questions-row {
		margin: 0 auto;
		max-width: 39rem;
	}

	.btn-draw-line {
		--bs-btn-border-color: transparent;
		--bs-btn-hover-border-color: transparent;
		--bs-btn-hover-bg: var(--bs-btn-bg);
		position: relative;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		width: 10rem;
		height: 10rem;
		border-radius: 0.5rem;
		touch-action: none; // so dragging the line on touch devices will work
		transition: background-color $reg-trans, color $reg-trans, border-color $reg-trans, box-shadow $long-trans, filter $reg-trans;
		transition-timing-function: ease;

		&:hover {
			&::after {
				border-color: var(--bs-btn-hover-bg);
			}
		}

		&::after {
			--size: 2.5rem;
			content: "";
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			width: var(--size);
			height: var(--size);
			border-radius: 50%;
			border: 0.5rem solid var(--bs-btn-bg);
			background-color: var(--bs-btn-bg);
			transition-property: background-color, border-color, border-width;
			transition-duration: $reg-trans;
			transition-timing-function: ease;
		}

		&.answer1,
		&.answer2 {
			user-select: none;
		}

		&.answer1 {
			--bs-btn-bg: #{$white};
			--bs-btn-border-color: inherit;
			--bs-btn-padding-y: 1.25rem;
			--bs-btn-padding-x: 1.25rem;
			filter: drop-shadow(0 0.5rem 0.75rem rgba(45, 62, 80, 0.2));

			&::after {
				right: calc(0% - (var(--size) / 2));
				background-color: $light-blue;
			}

			&.line-dragging {
				--bs-btn-bg: #{$b1-pale};
			}

			&.line-dragging,
			&.answer-selected {
				&::after {
					background-color: $primary;
				}
			}

			&.success {
				&::after {
					background-color: $vivid-blue;
				}
			}

			&.error {
				--bs-btn-bg: #{$stage2-light};

				&::after {
					background-color: $hero-rose;
				}
			}

			img {
				width: 100%;
				height: 100%;
				mix-blend-mode: multiply;
			}
		}

		&.answer2 {
			--bs-btn-bg: #{$light-blue};
			--bs-btn-color: #{$vivid-blue};
			--bs-btn-hover-color: var(--bs-btn-color);
			// z-index: 2; // so appears on top of line, as per design

			&::after {
				left: calc(0% - (var(--size) / 2));
				background-color: $white;
			}

			&.line-dragging,
			&.answer-selected,
			&.success,
			&.error {
				--bs-btn-color: #{$white};
			}

			&.line-dragging,
			&.answer-selected,
			&.error,
			&.success {
				--bs-btn-bg: #{$primary};

				&::after {
					border-width: 1rem;
				}
			}

			// &.audio-playing {
			// 	--bs-btn-bg: #{$primary};
			// 	--bs-btn-color: #{$white};
			// }

			&.success {
				--bs-btn-bg: #{$vivid-blue};
				filter: drop-shadow(0 0.5rem 0.75rem rgba(45, 62, 80, 0.2));
			}

			&.error {
				--bs-btn-bg: #{$stage2-light};
				--bs-btn-color: #{$hero-rose};
			}
		}
	}
}
