/* ==========================================================================
   Variables
   ========================================================================== */

/* Type settings */

$quicksand: "Quicksand", sans-serif;
$sassoon: "SassoonInfant", sans-serif;

$base-font-family: $quicksand;
$heading-font: $quicksand;

$base-font-size: 13px;
$base-line-height: 1.4;

/* Colour setting */

// Brand colours
// Primary
$hero-blue: #00b6f1;
$milo-blue: #75a7d9;

// Secondary
$b1-pale: #e0f4ff;
$b2-light: #b3e8fc;
$b3-vivid: #0078d0;
$dark-text: #2d3e50;

$off-white: #fff6f0;
$vivid-blue: #0078d0;
$vivid-blue-tinted: #00b7f1; // for link hover BGs
$light-blue: #b3e8fc;
$aura-blue: #fafaff;

// Tertiary
$golden: #fcb043;
$hero-rose: #ed1165;
$pips-hair: #f04e37;
$navy: #292562;
$turquoise: #32bcad;
$purple: #635bff;

// Stage colours
$stage-1: #ffcb05;
$stage1-light: #fff5be;
$stage1-dark: #e0b100;

$stage2: #ed1c24;
$stage2-light: #fbd2d3;
$red-tint: rgba($stage2-light, 0.5);
$stage2-dark: #c00b12;

$stage3: #72bf44;
$stage3-light: #e8f8de;
$stage3-dark: #469929;

$stage4: #0089cf;
$stage4-light: #daf2fe;
$stage4-dark: #006ea6;

$stage4-plus: #0095da;
$stage4-plus-light: #cceaf8;
$stage4-plus-dark: #0077ae;

$stage5: #32bcad;
$stage5-light: #d6f2ef;
$stage5-dark: #28968a;

$stage6: #8781bd;
$stage6-light: #ece3f5;
$stage6-dark: #6c6797;

$stage7: #f58220;
$stage7-light: #fde6d2;
$stage7-dark: #c4681a;

$stage8: #d93a85;
$stage8-light: #ffc8e1;
$stage8-dark: #98295d;

// Generic use
$white: #fff;
$black: #000;

$shades-100: #f9f9fa;
$shades-200: #e6e7e8;
$shades-400: #b1b3b5;
$shades-500: #6d6e71;
$shades-600: #3a3d43;
$shades-800: #181c21;

$grey-1: $shades-200;
$grey-2: $shades-500;

$alert-red: #ed1165;
$alert-amber: #fcb043;
$alert-green: #32bcad;

// fallback colours
$primary: $dark-text;
$secondary: $aura-blue;
$tertiary: $vivid-blue;

$success: $stage5-dark;
$info: $dark-text;
$danger: $hero-rose;

$error: $stage2-dark; // custom

$primary-rgb: red($primary), green($primary), blue($primary);
$secondary-rgb: red($secondary), green($secondary), blue($secondary);
$tertiary-rgb: red($tertiary), green($tertiary), blue($tertiary);

$short-trans: 100ms;
$reg-trans: 150ms; // to match Bootstrap
$long-trans: 300ms;

/* ==========================================================================
Bootstrap Overrides
========================================================================== */
$hr-opacity: 1;

$border-color: $shades-400;
$border-radius: 0.5rem;
$button-border-radius: 0.75rem;
$box-shadow: 0 0.25rem 1.5rem 0 rgba($hero-blue, 0.2);
$focus-ring-box-shadow: 0 0 0 0.125rem rgba($hero-blue, 0.2);

$input-bg: $white;
$input-padding-x: 1rem;
$input-padding-y: 0.75rem;
$input-btn-font-size: 16px; // anything below this will cause Apple devices to zoom in when focused
$input-btn-focus-width: 0.1rem;
$input-focus-bg: $white;
$input-focus-border-color: $hero-blue;
$input-border-validation-color: var(--input-border-validation-color, $alert-red);
$input-placeholder-color: $shades-500;
$input-validation-color: var(--input-validation-color, $alert-red);
$input-btn-focus-box-shadow: $focus-ring-box-shadow;

$form-select-focus-box-shadow: $input-btn-focus-box-shadow;

$toast-font-size: 1rem;
$toast-max-width: 37.5rem;
$toast-color: $white;
$toast-padding-x: 1.25rem;
$toast-padding-y: 1.25rem;
$toast-border-radius: 1rem;
$toast-border-width: 0;
$toast-spacing: 1.25rem; // the space between toasts
$toast-box-shadow: 0 0.25rem 1.5rem 0 rgba(0, 183, 241, 0.2);

$btn-close-width: 0.75rem;
$btn-close-color: $dark-text;
$btn-close-opacity: 1;

$table-bg: $white;
$table-border-color: $shades-200;
$table-cell-padding-y: 1.25rem;
$table-cell-padding-x: 0;
$table-cell-padding-y-sm: 0.75rem;
$table-cell-padding-x-sm: 0;

$alert-padding-y: 0.75rem;
$alert-padding-x: 1rem;
$alert-border-radius: 0.5rem;
$alert-border-width: 0;

$modal-backdrop-bg: $white;
$modal-backdrop-opacity: 0.8;
$modal-content-bg: $b1-pale;
$modal-content-border-radius: 2.5rem;
$modal-inner-padding: 2.5rem;
$modal-content-border-color: $vivid-blue;

$badge-padding-x: 0.5rem;
$badge-padding-y: 0.25rem;
$badge-font-size: 0.8125rem;
$badge-border-radius: 6rem;

$dropdown-min-width: 12rem;
$dropdown-font-size: 0.875rem;
$dropdown-box-shadow: 0 0.75rem 1.25rem 0 rgba(41, 37, 98, 0.1);
$dropdown-bg: $white;
$dropdown-border-color: $shades-200;
$dropdown-padding-y: 0.5rem;
$dropdown-padding-x: 0.5rem;
$dropdown-item-padding-y: 0.6rem;
$dropdown-item-padding-x: 0.75rem;
$dropdown-border-radius: 0.75rem;
$dropdown-link-hover-bg: rgba($b1-pale, 0.5);
$dropdown-link-hover-color: $tertiary;
$dropdown-link-active-bg: $light-blue;
$dropdown-link-active-color: $dropdown-link-hover-color;

$offcanvas-horizontal-width: 39.5rem;
$offcanvas-padding-x: 2.5rem;
$offcanvas-padding-y: 3.75rem;
$offcanvas-color: $primary;
$offcanvas-bg-color: $b1-pale;
$offcanvas-border-width: 0.0625rem;
$offcanvas-border-color: $shades-400;
$offcanvas-title-line-height: 1.2;

$accordion-padding-x: 2rem;
$accordion-padding-y: 1.25rem;
$accordion-border-radius: 1.25rem;
$accordion-inner-border-radius: 1.25rem;
$accordion-bg: $white;
$accordion-button-active-bg: $white;
$accordion-button-active-color: $tertiary;
$accordion-icon-color: $dark-text;
$accordion-icon-active-color: $tertiary;
$accordion-button-focus-box-shadow: none;

$tooltip-bg: $navy;
$tooltip-padding-y: 0.5rem;
$tooltip-padding-x: 0.5rem;
$tooltip-border-radius: 0.25rem;
$tooltip-opacity: 1;
$tooltip-max-width: 260px;

$form-check-input-checked-bg-color: var(--brand-tertiary);

$font-weight-normal: 500;

$font-family-base: $base-font-family;
$body-bg: $secondary;
$body-color: $primary;

// container gutter is / 2
$container-gutter-width-xs: 40px;
$container-gutter-width-sm: 40px;
$container-gutter-width-md: 40px;
$container-gutter-width-lg: 160px;
$container-gutter-width-xl: 160px;

// grid gutter is / 2
$grid-gutter-width-xs: 16px;
$grid-gutter-width-sm: 16px;
$grid-gutter-width-md: 16px;
$grid-gutter-width-lg: 24px;
$grid-gutter-width-xl: 24px;

$grid-gutter-width: $grid-gutter-width-xs;

// if gutter is used - container widths must be adjusted to match design
// container width + container gutter must still be less than breakpoint
$grid-breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 1280px,
	xl: 1676px,
);

$container-max-widths: (
	sm: 100%,
	md: 100%,
	lg: 1280px + $container-gutter-width-lg,
	xl: 1360px + $container-gutter-width-xl,
);
