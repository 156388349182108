.page-buyer-journey-register {
	--form-group-margin-bottom: 20px;

	padding: 40px 0;
	font-size: 16px; // overwriting base-font-size used in main product

	@include media-breakpoint-up(md) {
		--form-group-margin-bottom: 30px;
		padding: 80px 0;
	}

	.logo {
		margin-bottom: 40px;

		@include media-breakpoint-up(md) {
			margin-bottom: 80px;
		}
	}

	.form-group {
		margin-bottom: var(--form-group-margin-bottom);
	}

	.form-wrapper {
		overflow: hidden;
		border-radius: 20px;
		border: 1px solid $b2-light;
		background-color: $white;
		margin-left: auto;
		margin-right: auto;

		@include media-breakpoint-up(lg) {
			border-radius: 40px;
			width: 1200px;
		}
	}

	.header-wrapper {
		padding: 24px 20px;
		background-color: $hero-blue;
		color: $white;
		text-align: center;

		@include media-breakpoint-up(md) {
			padding: 48px 40px;
		}
	}

	.form-content {
		padding-inline: 20px;
		margin-block: 20px;
		display: flex;
		flex-direction: column;
		align-items: center;

		@include media-breakpoint-up(md) {
			padding-inline: 40px;
			margin-block-start: 80px;
			margin-block-end: 40px;
		}
	}

	.form-section-wrapper {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: calc(80px - var(--form-group-margin-bottom));
		margin-inline: auto;
	}

	.form-fields {
		width: 100%;
		max-width: 480px;
		margin-inline: auto;
	}

	.heading-section {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;
		text-align: center;

		@include media-breakpoint-up(md) {
			margin-bottom: 40px;
		}

		.form-heading {
			margin-bottom: 12px;
			color: $shades-800;
			font-weight: 700;

			@include media-breakpoint-up(md) {
				margin-bottom: 24px;
			}
		}

		.form-subheading {
			margin-bottom: 0;
			font-weight: 700;
			color: $shades-500;
		}
	}

	.tab-selection {
		display: flex;
		align-items: center;
		flex-direction: column;
		margin: 0;

		@include media-breakpoint-up(md) {
			margin: 10px 0 0;
		}
	}

	.tab-wrapper {
		border-radius: 24px;
		padding: 4px;
		background-color: $aura-blue;
		margin-bottom: 20px;
		display: flex;
		justify-content: center;
		align-items: center;

		@include media-breakpoint-up(md) {
			margin-bottom: 40px;
		}

		.tab-pill {
			@include h4;
			padding: 12px 32px;
			border-radius: 24px;
			cursor: pointer;
			border: none;
			color: $shades-500;
			background-color: $aura-blue;
			min-width: 11.25rem;

			&.active {
				background-color: $light-blue;
				color: $shades-800;
			}
		}
	}

	.subscription-card-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 20px;
		margin-bottom: 40px;

		@include media-breakpoint-up(md) {
			flex-direction: row;
			justify-content: center;
			align-items: flex-start;
			margin-bottom: 80px;
		}
	}

	.subscription-card {
		border: 1px solid $b2-light;
		box-shadow: 0px 8px 20px 0px rgba(0, 120, 208, 0.1);
		border-radius: 20px;
		width: 240px;
		background-color: $aura-blue;
		transition: opacity $reg-trans ease;

		&.selected-state {
			opacity: 0.2;

			&.active {
				box-shadow: 0px 8px 20px 0px rgba(0, 120, 208, 0.1), 0 0 0 8px $hero-blue;
				border-color: $hero-blue; // not changing border-width to 0 because that still causes the layout to shift slightly
				opacity: 1;

				.card-name {
					border-radius: 0;
				}

				.btn {
					background-color: $hero-blue;
					color: $white;
				}
			}

			&:hover {
				opacity: 1;
			}
		}

		.card-name {
			@include h3;
			background-color: $hero-blue;
			padding: 20px 0;
			border-top-left-radius: 20px;
			border-top-right-radius: 20px;
			color: $white;
			text-align: center;
		}

		.card-content {
			padding: 20px;
		}

		.price {
			display: flex;
			justify-content: center;
			align-items: flex-end;
			color: $shades-800;
			gap: 4px;
			margin-bottom: 4px;

			h1 {
				margin-bottom: 0;
			}

			.gst-note {
				font-size: 14px;
				font-weight: 700;
				line-height: 1.5;
			}
		}

		.time {
			font-weight: 700;
			color: $shades-500;
			text-align: center;
			margin-bottom: 20px;
		}

		.quantity {
			color: $vivid-blue;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;
			margin-bottom: 20px;

			.text {
				@include h4;
				margin-bottom: 0;
				line-height: 1;
			}
		}
	}

	.trial-card {
		border: 8px solid $turquoise;
		border-radius: 20px;
		box-shadow: 0px 4px 24px 0px rgba(0, 183, 241, 0.2);
		width: 100%;
		margin-bottom: 40px;

		@include media-breakpoint-up(md) {
			width: 480px;
			margin-bottom: 80px;
		}

		.card-name {
			@include h3;
			background-color: $turquoise;
			padding: 20px;
			color: $white;
			text-align: center;
		}

		.card-content {
			padding: 20px;
		}

		.price {
			display: flex;
			align-items: flex-end;
			justify-content: center;
			gap: 4px;
			margin-bottom: 12px;

			@include media-breakpoint-up(md) {
				flex-direction: column;
				align-items: center;
				margin-bottom: 20px;
			}

			h1 {
				margin-bottom: 0;
			}

			.note {
				font-size: 14px;
				font-weight: 700;
				line-height: 1.5;
			}
		}

		.access {
			margin-bottom: 12px;
			text-align: center;
			color: $stage5-dark;
			font-weight: 700;

			@include media-breakpoint-up(md) {
				margin-bottom: 20px;
			}
		}

		.details {
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;

			.icon {
				color: $vivid-blue;
			}

			li {
				display: flex;
				gap: 8px;
				justify-content: flex-start;
				align-items: flex-start;
				font-size: 14px;
				font-weight: 600;
				line-height: 1.5;

				&:not(:last-child) {
					margin-bottom: 12px;
				}
			}
		}
	}

	.info-box {
		border-radius: 8px;
		background-color: $b1-pale;
		color: $b3-vivid;
		padding: 12px 16px;
	}

	.summary-box {
		border-radius: 12px;
		border: 1px solid $b2-light;
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-top: 40px;
		padding: 20px;
		row-gap: 20px;
		color: $shades-800;
		background-color: $aura-blue;

		@include media-breakpoint-up(md) {
			border-radius: 20px;
			margin-top: 80px;
			padding: 40px;
			row-gap: 40px;
		}

		.title {
			color: $vivid-blue;
		}

		.info-row {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding-bottom: 20px;

			&:not(:last-child) {
				border-bottom: 1px solid $b2-light;
			}

			@include media-breakpoint-up(md) {
				padding-bottom: 40px;
			}

			.plan-text {
				@include h3;
				color: $shades-500;
				margin-left: 12px;
			}

			.total-text {
				color: $shades-500;
				margin-top: 12px;
				font-weight: 700;
			}
		}

		.row-left {
			&.check-box {
				display: flex;
				align-items: flex-start;
				justify-content: flex-start;
				gap: 8px;
				flex-shrink: 0;

				@include media-breakpoint-up(md) {
					width: 60%;
				}
			}

			input {
				flex-shrink: 0;
			}

			.promo-info {
				margin-top: 8px;

				p {
					margin-bottom: 0;
					font-weight: 700;
					margin-left: 8px;
				}
			}
		}

		.stripe-icon {
			color: $purple;
		}
	}

	.payment-method-wrapper {
		margin: 20px 0;

		@include media-breakpoint-up(md) {
			margin: 10px 0 40px;
		}
	}

	.payment-method-box {
		border-radius: 10px;
		border: 1px solid $shades-400;
		overflow: hidden;

		.radio-row-wrapper {
			&:not(:last-child) {
				border-bottom: 1px solid $shades-400;
			}

			&.active {
				background-color: $aura-blue;
			}

			.info-text {
				margin: 0 20px;
				font-size: 14px;
				font-weight: 600;
				line-height: 1.5;
				color: $shades-800;
				margin-bottom: 0;
			}

			.form-group {
				padding: 0 20px 20px;
			}
		}

		.form-check-input {
			margin-top: 0;
		}
	}

	.email-notification-box {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		color: $b3-vivid;
		background-color: $b1-pale;
		padding: 10px 12px;
		border-radius: 8px;
		width: 100%;

		@include media-breakpoint-up(md) {
			flex-direction: row;
			gap: 12px;
			padding: 12px 16px;
			align-items: center;
			justify-self: flex-start;
		}

		i {
			margin-block: 0.25rem;
		}

		.text {
			margin-bottom: 0;
			font-weight: 500;
		}
	}

	.form-control-checkbox {
		margin-top: 0;
	}

	.row-right {
		&.button-container {
			width: 100%;

			@include media-breakpoint-up(md) {
				width: auto;
			}
		}

		.btn {
			width: 100%;
		}
	}

	.back-to-top-btn-container {
		display: flex;
		width: 100%;
		justify-content: center;
		margin-top: 40px;

		@include media-breakpoint-up(md) {
			margin-top: 80px;
		}
	}
}

.promo-code-box {
	position: relative;

	input {
		border-radius: 8px;
		border: 1px solid $shades-400;
		height: 50px;
		padding: 8px 8px 8px 16px;
		width: 100%;

		&:focus,
		&:active {
			color: var(--bs-body-color);
			background-color: #fff;
			border-color: #00b6f1;
			outline: 0;
			box-shadow: 0 0 0 0.125rem rgba(0, 183, 241, 0.2);
		}
	}

	.btn-promo {
		position: absolute;
		right: 8px;
		top: 50%;
		transform: translateY(-50%);
		border-radius: 4px;
	}
}

.promo-code-box-success {
	height: 50px;
	border-radius: 8px;
	border: 1px solid $turquoise;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 16px;

	.success-info {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: 12px;

		.text {
			font-size: 14px;
			font-weight: 600;
			line-height: 1.5;
			color: $dark-text;
		}

		.icon {
			color: $turquoise;
		}
	}

	.btn-remove {
		&:hover,
		&:focus {
			color: $hero-rose;
			background-color: transparent;
		}
	}
}
