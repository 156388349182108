.page-help-centre {
	.category-section {
		gap: 1.25rem;
	}

	.category-title {
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
	}

	.btn-contact-us {
		min-width: 10rem;
	}

	.help-bar {
		border-radius: 1rem;
	}

	.accordion {
		.accordion-button,
		.accordion-body {
			padding-right: 1.25rem;
		}
	}
}
