.accordion {
	// custom vars
	--accordion-button-width: 3rem;
	--accordion-button-height: 3rem;
	//
	border-radius: $accordion-border-radius;
	box-shadow: 0 0 0 0 rgba(0, 183, 241, 0.2);
	transition-property: border-color, box-shadow;
	transition-duration: $reg-trans;
	transition-timing-function: ease;

	&:has(.accordion-button:is(:hover, :focus)) {
		--bs-accordion-border-color: #{$hero-blue};
		box-shadow: 0 0 0 0.125rem rgba(0, 183, 241, 0.2);
	}

	.accordion-item {
		&.disabled {
			pointer-events: none;

			.accordion-button {
				&::after {
					visibility: hidden;
				}
			}
		}
	}

	.accordion-button {
		&:hover {
			box-shadow: $accordion-button-focus-box-shadow;
		}

		&:not(:focus) {
			box-shadow: none;
		}

		&[aria-expanded="true"] {
			&::after {
				background-image: escape-svg($accordion-button-icon);
				background-color: $b1-pale;
			}
		}

		&:hover,
		&:focus {
			&::after {
				background-image: escape-svg($accordion-button-active-icon);
				background-color: $light-blue;
				color: $tertiary;
			}

			> * {
				color: $accordion-button-active-color;
				text-decoration-color: $accordion-button-active-color;
			}
		}

		&::after {
			width: var(--accordion-button-width);
			height: var(--accordion-button-height);
			background-position: center;
			border-radius: 0.75rem;
			transition-property: color, background-color;
			transition-duration: $reg-trans;
			transition-timing-function: ease;
		}

		> * {
			color: $accordion-button-color;
			transition: color $reg-trans ease;
		}
	}

	.accordion-body {
		padding-top: 0;
		padding-bottom: var(--bs-accordion-body-padding-x);
	}
}
