.modal {
	--bs-modal-width: 600px;

	&.modal-welcome {
		--bs-modal-border-color: #{$hero-blue};
	}

	&.modal-student-dashboard {
		--bs-modal-border-width: 0.25rem;
		--bs-modal-border-color: #{$aura-blue};

		.modal-button-group {
			.btn {
				--bs-btn-color: #{$primary};
				--bs-btn-bg: #{$white};
				--bs-btn-border-color: #{$light-blue};
				--bs-btn-hover-color: var(--bs-btn-color);
				--bs-btn-hover-bg: #{$light-blue};
				--bs-btn-hover-border-color: #{rgba($vivid-blue-tinted, 0.5)};
				--bs-btn-border-radius: 1rem;
				--bs-btn-border-width: 0.125rem;
				--bs-btn-focus-box-shadow: none;

				display: flex;
				flex-direction: column;
				gap: 0.625rem;
				width: 100%;
				aspect-ratio: 1;
				border-bottom-width: 0.375rem;

				&:active {
					border-top-width: 0.375rem;
					border-bottom-width: var(--bs-btn-border-width);
				}
			}
		}
	}

	.modal-content {
		box-shadow: 0 0.25rem 1.5rem 0 rgba(0, 183, 241, 0.2);
	}

	.modal-body {
		display: flex;
		flex-direction: column;
		gap: 1.25rem;
		text-align: center;

		.button-group {
			gap: 0.75rem;
		}
	}

	.close {
		position: absolute;
		top: 1.25rem;
		right: 1.25rem;
		color: $primary;
	}

	.button-group {
		margin-top: 1.25rem; // takes the 'gap' into account
	}

	.wrapper-inner {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		gap: 0.25rem;
		margin-bottom: 2.5rem;
		border-radius: 0.75rem;
		border: 0.0625rem solid #b1b3b5;
		background-color: #fafaff;
		overflow: hidden;
	}
}
