.layout-quiz {
	display: flex;
	flex-direction: column;
	flex-grow: 1;

	.audio-playing {
		--bs-heading-color: #{$vivid-blue};
	}

	.intro-is-showing {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		background: url(./../../../../public/svg/bg-question-marks.svg) center bottom / contain no-repeat transparent;
	}

	.text-quiz-intro {
		transition: color $long-trans ease;
	}

	.btn-option {
		--pulse-distance: 0.5rem;
		--line-color-rgb: #{red($hero-blue), green($hero-blue), blue($hero-blue)};
		--audio-playing-box-shadow: 0 0 0 calc(var(--pulse-distance) * 1) rgba(var(--line-color-rgb), 0.05), 0 0 0 calc(var(--pulse-distance) * 2) rgba(var(--line-color-rgb), 0.05),
			0 0 0 calc(var(--pulse-distance) * 3) rgba(var(--line-color-rgb), 0.05), 0 0 0 calc(var(--pulse-distance) * 4) rgba(var(--line-color-rgb), 0.05);
		--bs-btn-bg: var(--bs-btn-hover-bg);

		--bs-btn-bg: #{$light-blue};
		--bs-btn-color: #{$vivid-blue};
		--bs-btn-hover-bg: var(--bs-btn-bg);
		--bs-btn-hover-color: var(--bs-btn-color);
		--bs-btn-border-width: 0;
		--bs-btn-border-radius: 0.5rem;
		--bs-btn-padding-y: 2.25rem;
		--bs-btn-padding-x: 3rem;
		--bs-btn-focus-box-shadow: none;

		box-shadow: var(--bs-btn-focus-box-shadow);

		// mostly from Bootstrap, needed to add extra transition for box-shadow
		transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.75s ease;

		&.audio-playing {
			--bs-btn-focus-box-shadow: var(--audio-playing-box-shadow);
		}

		&.active {
			--bs-btn-bg: #{$dark-text};
			--bs-btn-color: #{$white};
		}

		&.error {
			--bs-btn-bg: #{$stage2-light};
			--bs-btn-color: #{$hero-rose};
			--bs-btn-border-color: var(--bs-btn-bg);
			--bs-btn-active-bg: var(--bs-btn-bg);
			--bs-btn-active-color: var(--bs-btn-color);
			--bs-btn-active-border-color: var(--bs-btn-border-color);
		}

		&.correct {
			--bs-btn-bg: #{$vivid-blue};
			--bs-btn-color: #{$white};
			--bs-btn-border-color: var(--bs-btn-bg);
			--bs-btn-active-bg: var(--bs-btn-bg);
			--bs-btn-active-color: var(--bs-btn-color);
			--bs-btn-active-border-color: var(--bs-btn-border-color);
		}
	}

	.circle-correct,
	.circle-incorrect {
		color: $white;
		padding: 0.5rem;
		border-radius: 50%;
		box-shadow: 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);

		i {
			@extend %svg-mask;
			width: 2rem;
			height: 2rem;
		}
	}

	.circle-correct {
		background-color: $turquoise;

		i {
			--svg-mask-image: url(../../../../public/svg/check.svg);
		}
	}

	.circle-incorrect {
		background-color: $hero-rose;

		i {
			--svg-mask-image: url(../../../../public/svg/cross.svg);
		}
	}
}
