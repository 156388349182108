.quiz-drag-and-drop {
	--answer-height: 5rem;

	.quiz-card-wrapper {
		// card padding + offset for answer tags included as CSS vars so JS can reference when snapping answer tags into positions
		// no rem here or else the JS calculations mess up
		--quiz-card-padding: 12px;
		--quiz-card-tag-offset: 120px;
		--quiz-tag-border-width: 1px;
	}

	.quiz-card {
		background-color: $white;
		border: 0.125rem solid transparent;
		padding: var(--quiz-card-padding);
		box-shadow: 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);
		border-radius: 1.25rem;
		user-select: none;
		transition: background-color $reg-trans ease;

		&.drop-area-collided {
			background-color: $b1-pale;
			border-color: $light-blue;
			box-shadow: 0 0 0 1.25rem rgba(0, 183, 241, 0.05), 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);

			.quiz-card-snap-area {
				background-color: $light-blue;
				border-color: $hero-blue;
			}
		}

		.quiz-card-snap-area {
			height: var(--answer-height);
			background-color: $shades-100;
			border: 2px dashed $shades-400; // no rem here, looks weird
			border-radius: 0.5rem;
			transition-property: background-color, border-color;
			transition-duration: $reg-trans;
			transition-timing-function: ease;
		}
	}

	.answer-tag {
		--bs-btn-color: #{$primary};
		--bs-btn-bg: #{$white};
		--bs-btn-color: #{$primary};
		--bs-btn-border-width: var(--quiz-tag-border-width);
		--bs-btn-border-color: #{$shades-200};
		--bs-btn-hover-color: #{$white};
		--bs-btn-active-bg: #{$dark-text};
		--bs-btn-padding-y: 1.25rem;
		--bs-btn-padding-x: 0.5rem;
		--bs-btn-focus-box-shadow: 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);
		--bs-btn-border-radius: 0.5rem;

		position: absolute;
		top: calc(100% + var(--quiz-card-tag-offset) - var(--answer-height));
		z-index: 1;
		width: calc(100% - var(--quiz-card-padding) * 2);
		height: var(--answer-height);
		box-shadow: var(--bs-btn-focus-box-shadow);
		cursor: grab;
		user-select: none;
		touch-action: none; // will fix drag/drop not working on touch devices

		&:active {
			cursor: grabbing;
		}

		&.error {
			--bs-btn-bg: #{$stage2-light};
			--bs-btn-color: #{$hero-rose};
			--bs-btn-border-color: var(--bs-btn-bg);
		}

		&.correct {
			--bs-btn-bg: #{$vivid-blue};
			--bs-btn-color: #{$white};
			--bs-btn-border-color: var(--bs-btn-bg);
		}
	}
}
