.component-book {
	--line-color-rgb: #{red($hero-blue), green($hero-blue), blue($hero-blue)};

	user-select: none; // don't want to be able to drag a highlight over the book

	.page-book-reader.is-zoomed & {
		scale: 1.5;
		cursor: move;
	}

	.book {
		--pulse-distance: 1rem;
		transition: opacity $long-trans ease 0.5s; // <Fade /> doesn't seem to be playing nicely here... am also including transition delay to help stop ugly image loading

		// plugin has a bug where the user can still flip the right last page or left first page, even if there's no page there
		// it's no longer maintained, and will likely never be fixed
		// as a hacky fix, these ::psuedos will steal the click events in the empty space where a page would appear
		&:is(.is-first-page, .is-last-page) .stf__parent::before {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			width: 50%;
			height: 100%;
			z-index: 1;
		}

		&.is-first-page {
			.stf__parent::before {
				left: 0;
			}

			.stf__item.--right.--simple img {
				// using box-shadow rather than border so width doesn't change when this is turned on and off
				box-shadow: -1px 0 0 0 rgba($black, 0.1);
			}
		}

		&.is-last-page {
			.stf__parent::before {
				right: 0;
			}

			.stf__item.--left.--simple img {
				// applying border-ish effect to back cover too
				box-shadow: 1px 0 0 0 rgba($black, 0.1);
			}
		}

		// styling logic for applying an 'audio playing' shadow to only the first page if audio is applied, or only the last page if not a double-page spread
		// the page should pulse instead of the book
		&:not(.is-first-page, .is-last-page.total-pages-is-even)::before,
		&:is(.is-first-page, .is-last-page.total-pages-is-even) .stf__block::before {
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			border-radius: 0.625rem;
			box-shadow: 0 0 0 0 rgba(var(--line-color-rgb), 0.1), 0 0 0 0 rgba(var(--line-color-rgb), 0.1), 0 0 0 0 rgba(var(--line-color-rgb), 0.1),
				0 0 0 0 rgba(var(--line-color-rgb), 0.1);
			opacity: 0;
			transition-property: box-shadow, opacity;
			transition-duration: 0.75s;
			transition-timing-function: ease;
		}

		&.is-last-page.total-pages-is-even .stf__block::before {
			left: 0;
			right: auto;
		}

		// ensures that the audio band that appears around book only appears around targeted page
		&:is(.is-first-page, .is-last-page.total-pages-is-even) .stf__block::before {
			width: 50%;
		}

		&.audio-playing {
			&:not(.is-first-page, .is-last-page.total-pages-is-even)::before,
			&:is(.is-first-page, .is-last-page.total-pages-is-even) .stf__block::before {
				box-shadow: 0 0 0 calc(var(--pulse-distance) * 1) rgba(var(--line-color-rgb), 0.1), 0 0 0 calc(var(--pulse-distance) * 2) rgba(var(--line-color-rgb), 0.1),
					0 0 0 calc(var(--pulse-distance) * 3) rgba(var(--line-color-rgb), 0.1), 0 0 0 calc(var(--pulse-distance) * 4) rgba(var(--line-color-rgb), 0.1);
				opacity: 1;
			}
		}
	}

	.hotspot {
		position: absolute;
		border-radius: 0.25rem 0.25rem 0 0;

		&,
		&::after {
			transition: background-color $long-trans ease;
		}

		&::after {
			--line-height: 0.25rem;
			content: "";
			position: absolute;
			bottom: calc(var(--line-height) * -1);
			left: 0;
			width: 100%;
			height: var(--line-height);
			border-radius: 0.625rem;
			background-color: rgba(var(--line-color-rgb), 0);
		}

		&:hover,
		&:focus-visible,
		&.audio-playing {
			outline: 0;

			&::after {
				background-color: rgba(var(--line-color-rgb), 0.5);
			}
		}
	}

	.stf__parent {
		z-index: 2; // to appear on top of buttons
	}

	.stf__block {
		.stf__item {
			--page-border-radius-amount: 0.5rem;
			// transform: translate3d(0px, 0px, 0px) rotate(0rad) !important; // the plugin's translate3d is what's causing the page to shift mid turn

			// adding a slight border around pages to provide contrast against the BG
			img {
				position: absolute;
				pointer-events: none;
				width: 100%;
				height: 100%;
				transition: box-shadow $long-trans ease;
			}

			&.--left,
			&.--right {
				&::after {
					content: "";
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					pointer-events: none;
				}
			}

			&.--left {
				&::after,
				img {
					border-top-left-radius: var(--page-border-radius-amount);
					border-bottom-left-radius: var(--page-border-radius-amount);
				}

				// will plug any half-pixel holes Chrome places between pages
				&::before {
					content: "";
					position: absolute;
					top: 0;
					left: calc(100% - 0.0625rem);
					z-index: -1; // always behind book
					width: 0.125rem;
					height: 100%;
					background-color: #e5e5e5; // same colour as page inner shadow against a white BG
					pointer-events: none;
				}

				&::after {
					box-shadow: inset 0.5px 0 0 0.0625rem rgba($black, 0.1);
				}
			}

			&.--right {
				&::after,
				img {
					border-top-right-radius: var(--page-border-radius-amount);
					border-bottom-right-radius: var(--page-border-radius-amount);
				}

				&::after {
					box-shadow: inset -0.5px 0 0 0.0625rem rgba($black, 0.1);
				}
			}
		}
	}

	.buttons-wrapper {
		bottom: 5%;
	}

	.btn-arrow-left,
	.btn-arrow-right,
	.btn-finish-book {
		--bs-btn-border-color: #{rgba($b1-pale, 0.5)};
		--bs-btn-border-width: 0.125rem;
		--bs-btn-hover-bg: #{$hero-blue};
		--bs-btn-hover-color: #{$white};
		--bs-btn-hover-border-color: var(--bs-btn-border-color);
		--bs-btn-border-radius: 300px;
		--bs-btn-focus-box-shadow: none;

		position: absolute;
		width: auto;
		height: min(6.5vw, 6.5rem); // design uses height for these buttons rather than padding-block...
		padding: 0 min(2.5vw, 2.5rem);
		transition-property: background-color, color, transform, opacity, box-shadow;
		transition-duration: $long-trans, $long-trans, $long-trans, $reg-trans, $reg-trans;
		transition-timing-function: ease;

		&:hover,
		&:focus-visible {
			box-shadow: 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);
		}

		// Bootstrap Fade's .show class when added to a button has the side-effect of being the same as the 'active' state, causing the colours to flash
		// those colours are overwritten here
		&.show {
			color: var(--bs-btn-color);
			background-color: var(--bs-btn-bg);
			border-color: var(--bs-btn-border-color);

			&:hover {
				color: var(--bs-btn-hover-color);
				background-color: var(--bs-btn-hover-bg);
				border-color: var(--bs-btn-hover-border-color);
			}
		}

		i {
			width: min(3vw, 3rem) !important;
			height: auto !important;
			aspect-ratio: 1;
		}
	}

	.btn-arrow-left {
		right: 100%;
		transform: translateX(min(4.5vw, 4.5rem));
		padding-right: min(6vw, 6rem);

		&:hover,
		&:focus-visible {
			transform: translateX(min(4.25vw, 4.25rem));
		}

		i {
			--svg-mask-image: url(./../../../public/svg/arrow-left.svg);
		}
	}

	.btn-arrow-right,
	.btn-finish-book {
		right: 0;
		transform: translateX(min(7.25vw, 7.25rem));
		padding-left: min(6vw, 6rem);

		&:hover,
		&:focus-visible {
			transform: translateX(min(7.5vw, 7.5rem));
		}
	}

	.btn-arrow-right {
		i {
			--svg-mask-image: url(./../../../public/svg/arrow-right.svg);
		}
	}

	.btn-finish-book {
		--bs-btn-bg: #{$turquoise};
		--bs-btn-hover-bg: #{$turquoise};
		--bs-btn-border-color: #{rgba($b1-pale, 0.5)};
		--bs-btn-hover-bg: #{$stage5-dark};
		display: flex;
		gap: 1rem;

		left: auto;
		transform: translateX(min(1.25vw, 1.25rem));
		padding-left: min(0.375vw, 0.375rem);
		padding-right: min(3vw, 3rem);
		border-radius: 50rem 0.5rem 0.5rem 50rem;

		&:hover,
		&:focus-visible {
			transform: translateX(min(0.5vw, 0.5rem));
		}

		i {
			--svg-mask-image: url(./../../../public/svg/arrow-right.svg);
		}

		.circle-illustration {
			position: relative;
			width: min(5.5vw, 5.5rem);
			aspect-ratio: 1;

			img {
				position: absolute;
				width: 75%;
				height: 75%;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				object-fit: contain;
			}
		}
	}
}
