body:has(.page-routine-item-page) {
	--bs-body-bg: #{$b1-pale};
}

.page-routine-item-page {
	--container-gutter-width: 300px; // so arrows don't disappear off sides of screen

	.logo-wrapper {
		height: var(--corner-btn-width);
	}

	.routine-item-wrapper {
		aspect-ratio: 16 / 9;
	}

	.routine-item {
		--line-color-rgb: #{red($hero-blue), green($hero-blue), blue($hero-blue)};
		--pulse-distance: 1rem;
		background-color: $white;
		border: 0.125rem solid rgba($black, 0.2);
		transition: box-shadow 0.75s ease;

		&.is-image-item {
			border-radius: 1.25rem;
		}

		@at-root .audio-playing#{&} {
			box-shadow: 0 0 0 calc(var(--pulse-distance) * 1) rgba(var(--line-color-rgb), 0.05), 0 0 0 calc(var(--pulse-distance) * 2) rgba(var(--line-color-rgb), 0.05),
				0 0 0 calc(var(--pulse-distance) * 3) rgba(var(--line-color-rgb), 0.05), 0 0 0 calc(var(--pulse-distance) * 4) rgba(var(--line-color-rgb), 0.05);
		}
	}

	.buttons-wrapper {
		bottom: 2.5rem;
	}

	.btn-arrow-left,
	.btn-arrow-right {
		--bs-btn-border-color: #{rgba($b1-pale, 0.5)};
		--bs-btn-border-width: 0.125rem;
		--bs-btn-hover-bg: #{$hero-blue};
		--bs-btn-hover-color: #{$white};
		--bs-btn-hover-border-color: var(--bs-btn-border-color);
		--bs-btn-focus-box-shadow: none;
		--bs-btn-padding-y: 2.25rem;
		--bs-btn-padding-x: 2.25rem;
		--bs-btn-border-radius: 300px;
		transition-property: background-color, color, transform, opacity, box-shadow;
		transition-duration: $long-trans, $long-trans, $long-trans, $reg-trans, $reg-trans;
		transition-timing-function: ease;
	}

	.btn-arrow-left {
		padding-right: 7.5rem;
		transform: translateX(-7.5rem);

		&:hover,
		&:focus-visible {
			transform: translateX(-7.75rem);
		}
	}

	.btn-arrow-right {
		padding-left: 7.5rem;
		transform: translateX(7.5rem);

		&:hover,
		&:focus-visible {
			transform: translateX(7.75rem);
		}
	}
}
