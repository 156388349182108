.page-awards {
	margin: 4rem 0 0;

	.container-awards {
		@include media-breakpoint-between(0, lg) {
			--container-gutter-width: #{$container-gutter-width-lg};
		}
	}

	.main-wrapper {
		gap: 1.25rem;
		padding: 1.25rem;
		background-color: $b1-pale;
		border-radius: 2.5rem;

		@include media-breakpoint-up(lg) {
			gap: 2.5rem;
			padding: 2.5rem;
		}
	}

	.section-awards-top {
		gap: 1.25rem;

		.awards-top-box {
			gap: 1.25rem;
			padding: 2.5rem;
			border-radius: 1.25rem;

			@include media-breakpoint-up(lg) {
				gap: 2.75rem;
				height: 12.5rem;
				padding-block: 0;
			}
		}
	}

	.all-days-read {
		// needed CSS specificity boost to override styles further below
		.day.has-read.has-read {
			.day-line {
				background-color: $stage-1;
			}

			.day-circle {
				color: $stage-1;
				background-color: $stage1-dark;
				border-color: $stage-1;
			}
		}
	}

	.day {
		--day-border-width: 0.25rem;
		--day-circle-size: 3.25rem;
		height: 6.25rem;

		&.has-read {
			+ .has-read {
				.day-line {
					background-color: $hero-blue;
				}
			}

			.day-circle {
				background-color: $vivid-blue;
				border-color: $hero-blue;

				i {
					color: $white;
				}
			}
		}

		.day-line {
			--height-of-line: 0.75rem;
			content: "";
			position: absolute;
			right: calc(100% - var(--day-border-width));
			bottom: calc((var(--day-circle-size) / 2) - (var(--height-of-line) / 4));
			width: 50vh; // some large number
			height: var(--height-of-line);
			background-color: $light-blue;
		}

		.day-circle {
			position: relative;
			z-index: 6; // to be above the line generated z-indexes
			width: var(--day-circle-size);
			height: var(--day-circle-size);
			background-color: $light-blue;
			border: var(--day-border-width) solid $light-blue;
		}
	}

	.section-awards-badges {
		padding: 2.5vw;
		border-radius: 1.25rem;

		@include media-breakpoint-up(lg) {
			padding: 5rem;
		}

		.award-wrapper {
			gap: 1.25rem;
			color: $shades-400;
		}

		.award {
			width: 100%;
			aspect-ratio: 1;

			* {
				width: 100%;
				height: 100%;
			}

			img {
				box-shadow: 0 6.4px 9.6px 0 rgba(45, 62, 80, 0.2);
			}
		}

		.dashed-border {
			border: 0.125rem dashed;
		}

		.row-awards {
			--grid-gutter-width: 2.25rem;
			row-gap: 2.25rem;

			@include media-breakpoint-up(lg) {
				--grid-gutter-width: 3.75rem;
				row-gap: 3.75rem;
			}
		}
	}
}
