.gap-2-5 {
	gap: 0.75rem !important;
}

.gap-3-5 {
	gap: 1.25rem !important;
}

.column-gap-3-5 {
	column-gap: 1.25rem !important;
}

.gap-4-25 {
	gap: 2.25rem !important;
}

.gap-4-5 {
	gap: 2.5rem !important;
}

.mt-2-5 {
	margin-top: 0.75rem !important;
}

.mt-4-5 {
	margin-top: 2.5rem !important;
}

.mb-2-5 {
	margin-bottom: 0.75rem !important;
}

.mb-4-5 {
	margin-bottom: 2.5rem !important;
}

.py-2-5 {
	padding-block: 0.75rem !important;
}

.px-2-5 {
	padding-inline: 0.75rem !important;
}

.px-3-5 {
	padding-inline: 1.25rem !important;
}

.g-2-5 {
	--bs-gutter-y: 0.75rem;
	--bs-gutter-x: 0.75rem;
}

@each $angle in (90, 180, 270) {
	.rotate-#{$angle} {
		rotate: #{$angle}deg;
	}
}

.z-4 {
	z-index: 4 !important;
}

.z-5 {
	z-index: 5 !important;
}

.border-light-blue {
	border-color: $light-blue !important;
}

.border-hero-blue {
	border-color: $hero-blue !important;
}

.bg-light-blue {
	background-color: $light-blue !important;
}

.bg-pale-blue {
	background-color: $b1-pale !important;
}

.text-decoration-none {
	text-decoration: none !important;
}
