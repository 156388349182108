.page-settings {
	.bordered-list {
		.header {
			justify-content: flex-start;
			gap: 2rem;
			background-color: $white;
			padding-right: 3rem;
			padding: 1.5rem 3rem 1.5rem 1.5rem;
		}
	}

	.account-image-wrapper {
		position: relative;
		padding: 1rem;
		background-color: $b1-pale;

		&,
		&.organisation img {
			border-radius: 1.25rem;
		}

		&.organisation {
			padding: 0;
			flex-shrink: 0;
			width: 5rem;
			height: 5rem;
			background-color: $hero-blue;

			img {
				position: absolute;
				width: 100%;
				height: 100%;
			}
		}
	}
}

.modal-change-subscription {
	.subscription-item {
		padding: 1.25rem;
		color: $shades-800;

		&:not(.active) {
			background-color: $white;
		}

		&.faded {
			&,
			.subscription-text-users {
				color: $shades-500;
			}
		}

		+ .subscription-item {
			border-top: 1px solid $shades-400;
		}

		.subscription-text-users {
			color: $vivid-blue;
		}
	}
}

.modal-upload-photo {
	.upload-photo {
		--bs-btn-bg: #{$white};
		--bs-btn-color: #{$shades-200};
		--bs-btn-border-color: #{$shades-400};

		--bs-btn-hover-bg: #{$shades-100};
		--bs-btn-hover-color: var(--bs-btn-color);
		--bs-btn-hover-border-color: #{$shades-200};

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		gap: 1.25rem;
		width: 10rem;
		height: 10rem;
		padding: 0.75rem;
		overflow: hidden;
		border: 2px dashed;
		border-radius: 1.25rem;

		&:hover,
		&:focus-visible,
		&:focus-within {
			.btn-choose-file {
				--bs-btn-bg: transparent;
				--bs-btn-color: var(--bs-btn-hover-color);
				--bs-btn-border-color: var(--bs-btn-hover-border-color);
			}
		}

		&.has-organisation-preview {
			padding: 0;
			border-style: solid;
		}
	}
}
