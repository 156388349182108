.page-resources {
	.resources-section {
		--bs-border-color: #{$shades-400};
		border-radius: 1.25rem;
		overflow: hidden;
	}

	.resources-item {
		--bs-border-color: #{$shades-200};
		background-color: $white;

		&:first-child {
			--bs-border-color: #{$shades-400};
		}
	}

	.resource-title-wrapper {
		background-color: $shades-100;
	}

	.resources-icon {
		border-radius: 0.75rem;
		background-color: $stage5-light;
	}

	.resource-link {
		&:not(:hover) {
			color: $primary;
			text-decoration-color: transparent;
		}
	}
}
