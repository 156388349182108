.alert {
	&.alert-info {
		--bs-alert-bg: #{$b1-pale};
		--bs-alert-color: #{$vivid-blue};
	}

	&.alert-danger {
		--bs-alert-bg: #{$red-tint};
		--bs-alert-color: #{$hero-rose};
	}
}
