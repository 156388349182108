/* ==========================================================================
   Base styles: opinionated defaults
   ========================================================================== */

html {
	font-size: var(--base-font-size);
	line-height: var(--base-line-height);
	height: 100%;
	zoom: 1;

	font-smooth: always;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

body {
	display: flex;
	flex-direction: column;
	min-height: 100%;
	width: 100%;
	margin: 0;
	flex-grow: 1;
	flex-shrink: 0;
	scroll-behavior: smooth;
}

/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */
::selection {
	background: rgba(var(--rgb-brand-primary), 0.5);
	text-shadow: none;
}

/*
 * Remove the gap between images, videos, audio and canvas and the bottom of
 * their containers: h5bp.com/i/440
 */

audio,
canvas,
img,
svg,
video {
	vertical-align: middle;
}

/*
 * Remove default fieldset styles.
 */

fieldset {
	border: 0;
	margin: 0;
	padding: 0;
}

/*
 * Allow only vertical resizing of textareas.
 */

textarea {
	resize: vertical;
}

img {
	max-width: 100%;

	&:not([width]) {
		width: auto;
	}

	&:not([height]) {
		height: auto;
	}
}

svg {
	max-width: 100%;
	max-height: 100%;
}

figure {
	margin: 0;
}

// App router
/*
body.loading > *:not(#modal) {
	visibility: hidden;
}
*/

// Page router
body.loading {
	[id="__next"] {
		.alert-bar,
		> header,
		> main,
		> footer {
			visibility: hidden;
		}
	}
}

.container,
.container-fluid {
	--bs-gutter-x: var(--container-gutter-width);
}

@each $breakpoint, $container-max-width in $container-max-widths {
	.container-#{$breakpoint} {
		--bs-gutter-x: var(--container-gutter-width);
	}
}

.container .container {
	padding: 0;
}
