// to be used for general RTE styles
.wysiwyg-content {
	> * {
		&:last-child {
			margin-bottom: 0;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	table,
	ol,
	ul,
	dl {
		margin-bottom: var(--p-spacing);
	}

	img {
		max-width: 100%;
		// override umbraco's fixed width and height on RTE images
		width: auto;
		height: auto;
	}

	li {
		p {
			margin-bottom: 0;
		}
	}
}
