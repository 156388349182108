[id="root"] {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

main {
	flex-grow: 1;
	overflow: hidden; // for page-flipping
}

// turning off the default nav hyperlink for several items
.nav-link,
.dropdown-item {
	&::before {
		display: none;
	}
}

.container-large-gutters {
	--container-gutter-width: 260px; // so items don't appear behind home icon
}

.grecaptcha-badge {
	display: none !important;
}
