// use '--svg-mask-image: url({mask-path});' on the element where class is applied to set the masking image
.svg-mask,
%svg-mask {
	display: block;
	flex-shrink: 0;
	background-color: currentColor;
	mask-image: var(--svg-mask-image);
	mask-size: contain;
	mask-position: center;
	mask-repeat: no-repeat;

	// !important because some stuff like Swiper pre-bakes background-color styles in the nav arrows
	@media (forced-colors: active) {
		background-color: LinkText !important;
	}
}
