.toast-nag {
	position: absolute;
	bottom: 0;
	right: 40px;
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
	padding: 1.5rem 1.25rem;
	background-color: $stage2-dark;
	border-radius: 1rem 1rem 0 0;
	z-index: 5;

	.btn {
		--bs-btn-focus-box-shadow: 0 0 0 0.125rem rgba(255, 255, 255, 0.2);

		&:focus {
			box-shadow: none;
		}

		&:focus-visible {
			box-shadow: var(--bs-btn-focus-box-shadow);
		}
	}
}
