.drawn-line {
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;

	&.error {
		line {
			stroke: $hero-rose;
		}
	}

	&.success {
		line {
			stroke: $vivid-blue;
		}
	}

	line {
		stroke: $primary;
		stroke-dasharray: 6 6;
		stroke-width: 3;
		transition: stroke $reg-trans ease;
	}
}
