.student-admin-page {
	.student-management-wrapper {
		.student-management-item {
			&:not(:first-child) {
				.heading {
					margin-top: 1.5rem;
				}
			}

			.heading {
				min-height: 4rem;
				padding-block: 0.5rem;
				display: flex;
				align-items: center;
				justify-content: space-between;
				border-bottom: 0.0625rem solid $shades-400;
			}
		}
	}

	.class-icon-wrapper {
		width: 3rem;
		height: 3rem;
		border-radius: 0.75rem;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 0.0625rem dashed $shades-400;
	}
}
