.tooltip {
	&.bs-tooltip-top {
		margin-bottom: -0.4rem; // opposite of what the default arrow height was in Bootstrap (0.4rem), so it applies a negative offset
	}

	&.tooltip-favorite {
		--bs-tooltip-max-width: 5rem;
	}

	.tooltip-inner {
		@include label;
	}

	.tooltip-arrow {
		display: none;
	}
}
