.student-elibrary-book {
	position: relative;
	aspect-ratio: 242 / 300;
	transition: transform $long-trans, box-shadow $long-trans;
	transition-timing-function: ease;

	&,
	.book-shadow,
	img {
		border-radius: 0.25rem 0.5rem 0.5rem 0.25rem;
	}

	.book-shadow,
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.book-shadow {
		box-shadow: 0 0.125rem 0 0 rgba(255, 255, 255, 0.5) inset, 0 -0.0625rem 0 0.0625rem rgba(0, 0, 0, 0.2) inset;
	}
}
