.toast-container {
	position: fixed; // so stays on top while scrolling
}

.toast {
	i {
		@extend %svg-mask;
		width: 1.5rem;
		height: 1.5rem;

		@at-root .bg-danger#{&} {
			--svg-mask-image: url("../../../../public/svg/alert-solid.svg");
		}

		@at-root .bg-info#{&} {
			--svg-mask-image: url("../../../../public/svg/info-solid.svg");
		}

		@at-root .bg-success#{&} {
			--svg-mask-image: url("../../../../public/svg/check-solid.svg");
		}
	}
}
