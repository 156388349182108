// autoprefixer does not correctly prefix appearance
// sass-lint:disable-block no-vendor-prefixes
button,
[type="button"],
[type="submit"] {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.btn,
%btn {
	--bs-btn-bg: #{$vivid-blue};
	--bs-btn-color: #{$white};
	--bs-btn-border-color: transparent;
	--bs-btn-border-width: 0.0625rem;
	--bs-btn-border-radius: #{$button-border-radius};

	--bs-btn-hover-bg: #{$hero-blue};
	--bs-btn-hover-color: #{$white};

	--bs-btn-padding-x: 1.25rem;
	--bs-btn-padding-y: 1rem;

	--bs-btn-disabled-bg: #{$vivid-blue};
	--bs-btn-disabled-border-color: var(--bs-btn-disabled-bg);
	--bs-btn-disabled-color: #{$white};
	--bs-btn-disabled-opacity: 0.2;

	--bs-btn-active-bg: var(--bs-btn-hover-bg);
	--bs-btn-active-color: var(--bs-btn-hover-color);
	--bs-btn-active-border-color: var(--bs-btn-hover-border-color);
	--bs-btn-focus-box-shadow: #{$input-btn-focus-box-shadow};
	--bs-btn-font-weight: 700;

	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	line-height: 1;
	gap: 0.5rem;

	&:focus {
		box-shadow: var(--bs-btn-focus-box-shadow);
	}

	&:not(.btn-link)::before {
		// so doesn't clash with the hyperlink styles, in case a link needs to look like a button
		display: none;
	}

	i {
		@extend %svg-mask;
		width: 1.5rem;
		height: 1.5rem;
	}

	&.show-dot {
		&::after {
			content: "";
			width: 0.5rem;
			height: 0.5rem;
			margin-left: 0.25rem;
			background-color: $hero-rose;
			border-radius: 50%;
		}
	}
}

.btn-primary {
	--bs-btn-border-color: transparent;
	--bs-btn-hover-border-color: var(--bs-btn-border-color);
}

.btn-sm {
	--bs-btn-padding-x: 1rem;
	--bs-btn-padding-y: 0.5rem;
}

.btn-lg {
	--bs-btn-padding-x: 1.5rem;
	--bs-btn-padding-y: 1.4rem;
	--bs-btn-font-size: 1rem;
	--bs-btn-border-radius: 1rem;
}

.btn-icon {
	--bs-btn-bg: #{$vivid-blue};
	--bs-btn-hover-bg: #{$b1-pale};
	--bs-btn-color: #{$white};
	--bs-btn-hover-color: #{$shades-800};
	--bs-btn-border-color: transparent;
	--bs-btn-hover-border-color: transparent;
	--bs-btn-border-radius: 300px;
	--bs-btn-disabled-opacity: 1;

	width: 3rem;
	height: 3rem;

	&.btn-sm {
		width: 2rem;
		height: 2rem;
	}

	&.error {
		--bs-btn-bg: #{$hero-rose};
		--bs-btn-color: #{$white};
		--bs-btn-border-color: #{rgba($b1-pale, 0.5)};
		--bs-btn-hover-bg: #{$hero-rose};
		--bs-btn-focus-box-shadow: 0 0 0 0.125rem #{rgba($hero-rose, 0.2)};
	}

	&.success {
		--bs-btn-bg: #{$turquoise};
		--bs-btn-color: #{$white};
		--bs-btn-border-color: #{rgba($b1-pale, 0.5)};
		--bs-btn-hover-bg: #{$stage5-dark};
	}

	i {
		width: 1.5rem;
		height: 1.5rem;
	}
}

// uses this name in the design
.btn-quiz-forward {
	--bs-btn-border-color: #{rgba($b1-pale, 0.5)};
	--bs-btn-hover-color: #{$white};
	--bs-btn-hover-bg: #{$hero-blue};
	--bs-btn-hover-border-color: var(--bs-btn-border-color);
	--bs-btn-focus-box-shadow: 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);
	--bs-btn-disabled-opacity: 1;

	width: var(--corner-btn-width);
	height: var(--corner-btn-width);

	&:hover,
	&:focus-visible {
		box-shadow: var(--bs-btn-focus-box-shadow);
	}
}

.btn-icon-white {
	--bs-btn-bg: transparent;
	--bs-btn-color: #{$primary};
}

// named this in design
.btn-large-button {
	@include h1;
	--bs-btn-border-color: #{rgba($b1-pale, 0.5)};
	--bs-btn-hover-border-color: var(--bs-btn-border-color);
	--bs-btn-border-width: 0.125rem;
	--bs-btn-border-radius: 300px;
	--bs-btn-padding-y: 2.25rem;
	--bs-btn-padding-x: 3rem;
	--bs-btn-focus-box-shadow: 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);
	--bs-btn-disabled-opacity: 1;
	gap: 1.25rem;

	&:hover,
	&:focus-visible {
		box-shadow: var(--bs-btn-focus-box-shadow);
	}
}

.btn-white {
	--bs-btn-bg: #{$white};
	--bs-btn-color: #{$primary};
	--bs-btn-border-color: #{$shades-400};

	--bs-btn-hover-bg: #{$b1-pale};
	--bs-btn-hover-color: #{$vivid-blue};
	--bs-btn-hover-border-color: #{$hero-blue};

	--bs-btn-disabled-bg: var(--bs-btn-bg);
	--bs-btn-disabled-color: var(--bs-btn-color);
	--bs-btn-disabled-border-color: var(--bs-btn-border-color);
}

.btn-green {
	--bs-btn-bg: #{$turquoise};
	--bs-btn-color: #{$white};
	--bs-btn-border-color: #{rgba($b1-pale, 0.5)};

	--bs-btn-hover-bg: #{$stage5-dark};
	--bs-btn-hover-color: var(--bs-btn-color);
	--bs-btn-hover-border-color: var(--bs-btn-border-color);
}

.btn-destructive {
	--bs-btn-bg: #{$stage2-dark};
	--bs-btn-color: #{$white};
	--bs-btn-border-color: #{$stage2-dark};
	--bs-btn-hover-bg: #{$hero-rose};
	--bs-btn-hover-color: #{$white};
	--bs-btn-hover-border-color: #{$hero-rose};
	--bs-btn-disabled-bg: var(--bs-btn-bg);
	--bs-btn-disabled-color: var(--bs-btn-color);
	--bs-btn-focus-box-shadow: 0 0 0 0.125rem #{rgba($hero-rose, 0.2)};
}

.btn-destructive-outline {
	--bs-btn-bg: #{$white};
	--bs-btn-color: #{$dark-text};
	--bs-btn-border-color: #{$hero-rose};
	--bs-btn-hover-bg: #{$red-tint};
	--bs-btn-hover-color: #{$stage2-dark};
	--bs-btn-hover-border-color: #{$hero-rose};
	--bs-btn-disabled-bg: var(--bs-btn-bg);
	--bs-btn-disabled-color: var(--bs-btn-color);
	--bs-btn-focus-box-shadow: 0 0 0 0.125rem #{rgba($hero-rose, 0.2)};
}

%btn-link,
.btn-link {
	@include text-link;
	--bs-btn-color: var(--brand-tertiary);
	--bs-btn-bg: transparent;
	--bs-btn-hover-color: var(--bs-btn-color);
	--bs-btn-hover-bg: var(--bs-btn-bg);
	--bs-btn-disabled-color: #{$dark-text};
	--bs-btn-disabled-opacity: 0.5;

	vertical-align: baseline; // resetting v-alignment, since a .btn adds its own

	&.text-hero-rose {
		--bs-btn-color: #{$hero-rose};
	}

	&.dark-text {
		--bs-btn-color: #{$dark-text};
		--bs-btn-hover-color: var(--brand-tertiary);
	}
}

.btn-nav-link {
	@include nav-link;

	border: 0;
	background: none;
	padding: 0;
	justify-content: flex-start;
	position: relative;
	display: inline-flex;

	&::before {
		--offset-x: 0.5rem;
		--offset-y: 0.25rem;
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: calc(100% + var(--offset-x));
		height: calc(100% + var(--offset-y));
		background-color: rgba($vivid-blue-tinted, 0);
		border-radius: 0.5rem;
		pointer-events: none;
		transition: background-color $reg-trans ease;
	}

	&:hover,
	&:focus-visible {
		// design has forced our hand in using this ::before, because the hover state has padding around it but we don't want that affecting other text...
		// need to be turned off for links that look like buttons and certain elements
		// these are controlled in structure.scss
		&::before {
			background-color: rgba($vivid-blue-tinted, 0.2);
		}
	}
}

.btn-reset {
	border: 0;
	background: none !important;
	padding: 0;
}

.reset-select {
	--bs-btn-color: #{$black};
	--bs-btn-hover-color: #{$vivid-blue};
	--bs-btn-padding-x: 0;
	--bs-btn-padding-y: 0;
	--bs-btn-border-radius: 0;
}

.btn-input-clear {
	--bs-btn-padding-x: 1rem;
	--bs-btn-padding-y: 1rem;
	--bs-btn-bg: transparent;
	--bs-btn-color: #{$primary};
	--bs-btn-hover-bg: var(--bs-btn-bg);
	--bs-btn-hover-color: var(--bs-btn-color);
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
	color: $primary;

	&:hover {
		box-shadow: none;
	}

	i {
		--svg-mask-image: url(./../../../../public/svg/cross.svg);
		width: 1rem;
		height: 1rem;
	}
}

// am making separate classes for these, since that's how they are in the Figma styleguide
.btn-download,
.btn-ellipsis-vertical,
.btn-favorite,
.btn-ellipsis-horizontal {
	--bs-btn-bg: transparent;
	--bs-btn-hover-bg: #{$b1-pale};
	--bs-btn-color: #{$navy};
	--bs-btn-hover-color: var(--bs-btn-hover-color);
	--bs-btn-active-bg: #{$vivid-blue};
	--bs-btn-active-color: #{$white};
}

.btn-download {
	i {
		--svg-mask-image: url(./../../../../public/svg/download.svg);
	}
}

.btn-ellipsis-vertical {
	i {
		--svg-mask-image: url(./../../../../public/svg/ellipsis-vertical.svg);
	}
}

.btn-favorite {
	i {
		--svg-mask-image: url(./../../../../public/svg/star.svg);

		@at-root .favorited#{&} {
			--svg-mask-image: url(./../../../../public/svg/star-filled.svg);
		}
	}
}

.btn-ellipsis-horizontal {
	i {
		--svg-mask-image: url(./../../../../public/svg/ellipsis-vertical.svg);
	}
}

.btn-dropdown {
	--bs-btn-padding-y: 1rem;
	--bs-btn-padding-x: 1rem;
	--bs-btn-bg: #{$b1-pale};
	--bs-btn-color: #{$primary};
	--bs-btn-border-color: var(--bs-btn-bg);

	--bs-btn-hover-bg: var(--bs-btn-bg);
	--bs-btn-hover-color: #{$vivid-blue};
	--bs-btn-hover-border-color: var(--bs-btn-bg);

	--bs-btn-active-border-color: #{$hero-blue};
	--bs-btn-active-color: #{$vivid-blue};

	--bs-btn-disabled-bg: var(--bs-btn-bg);
	--bs-btn-disabled-color: var(--bs-btn-color);

	&:focus-visible {
		border-color: $hero-blue;
	}

	&:not(.btn-icon) {
		&::after {
			@extend %svg-mask;
			--svg-mask-image: url(./../../../../public/svg/chevron-down.svg);
			width: 1rem;
			height: 1rem;
		}
	}

	&.btn-icon {
		--bs-btn-padding-y: 0.75rem;
		--bs-btn-padding-x: 0.75rem;

		&:not(.hovered-style) {
			--bs-btn-bg: transparent;
			--bs-btn-hover-bg: #{$b1-pale};
		}

		&::after {
			display: none;
		}
	}
}

.btn-select {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	--bs-btn-bg: transparent;
	--bs-btn-border-color: #{$shades-400};

	&:hover,
	&:active {
		--bs-btn-border-color: #{$shades-400};
		--bs-btn-hover-border-color: #{$shades-400};
	}

	&:disabled {
		background-color: $shades-100;
		border-color: $shades-400;
		opacity: 1;
	}
}

.btn-audio-icon {
	--bs-btn-bg: #{$white};
	--bs-btn-color: #{$hero-blue};
	--bs-btn-border-color: #{rgba($vivid-blue-tinted, 0.2)};
	--bs-btn-hover-bg: #{$b1-pale};
	--bs-btn-hover-color: #{$vivid-blue};
	--bs-btn-hover-border-color: #{$hero-blue};
	--bs-btn-focus-box-shadow: 0 0.25rem 1.5rem 0 #{rgba($vivid-blue-tinted, 0.2)};
	--bs-btn-border-width: 0.0625rem;
	--bs-btn-border-radius: 0.5rem;
	--bs-btn-padding-x: 0.25rem;
	--bs-btn-padding-y: 0.25rem;
	--bs-btn-font-size: 1.125rem;

	flex-shrink: 0;
	box-shadow: var(--bs-btn-focus-box-shadow);

	&.has-text {
		padding: 0.5rem 0.75rem 0.5rem 1rem;

		.audio-playing & {
			.text {
				color: $white;
			}
		}

		.text {
			color: $vivid-blue;
			transition: $long-trans color ease;
		}
	}

	.audio-playing & {
		--bs-btn-focus-box-shadow: 0 0 0 0.5rem #{rgba($vivid-blue-tinted, 0.1)};
		background-color: $vivid-blue;
		color: $white;
		border-color: $vivid-blue;

		i {
			--svg-mask-image: url(./../../../../public/svg/audio-loud.svg);
		}
	}

	i {
		--svg-mask-image: url(./../../../../public/svg/audio-soft.svg);
		width: 2rem;
		height: 2rem;
	}
}

.btn-home-corner {
	--bs-btn-bg: #{$white};
	--bs-btn-color: #{$vivid-blue};
	--bs-btn-border-color: #{rgba($vivid-blue-tinted, 0.2)};
	--bs-btn-hover-bg: #{$hero-blue};
	--bs-btn-hover-color: #{$white};
	--bs-btn-hover-border-color: #{rgba($b1-pale, 0.5)};
	--bs-btn-focus-box-shadow: 0 0.25rem 1.5rem 0 #{rgba($vivid-blue-tinted, 0.2)};
	position: absolute;
	top: 0;
	left: 0;
	z-index: $btn-home-corner;
	width: var(--corner-btn-width);
	height: var(--corner-btn-width);
	padding: 2.25rem;
	border-top-width: 0;
	border-right-width: 0.125rem;
	border-bottom-width: 0.125rem;
	border-left-width: 0;
	border-radius: 0 0 2.5rem;
	box-shadow: var(--bs-btn-focus-box-shadow);

	i {
		--svg-mask-image: url(./../../../../public/svg/home.svg);
		width: 3rem;
		height: 3rem;
	}
}

.btn-top-right {
	@include body-small;
	--bs-btn-bg: #{$white};
	--bs-btn-color: #{$dark-text};
	--bs-btn-border-color: #{rgba($vivid-blue-tinted, 0.2)};
	--bs-btn-hover-bg: #{$hero-blue};
	--bs-btn-hover-color: #{$white};
	--bs-btn-hover-border-color: #{rgba($b1-pale, 0.5)};
	--bs-btn-focus-box-shadow: 0 0.25rem 1.5rem 0 #{rgba($vivid-blue-tinted, 0.2)};
	position: absolute;
	top: 0;
	right: 0;
	z-index: $btn-home-corner;
	flex-direction: column;
	gap: 0.25rem;
	width: var(--corner-btn-width);
	height: var(--corner-btn-width);
	padding: 2.25rem 0.625rem;
	border-top-width: 0;
	border-right-width: 0;
	border-bottom-width: 0.125rem;
	border-left-width: 0.125rem;
	border-radius: 0 0 0 2.5rem;
	box-shadow: var(--bs-btn-focus-box-shadow);

	i {
		width: 3rem;
		height: 3rem;
	}
}

.btn-row-label {
	--bs-btn-bg: #{$white};
	--bs-btn-color: #{$primary};
	--bs-btn-border-color: transparent;
	--bs-btn-hover-bg: #{$b1-pale};
	--bs-btn-hover-color: #{$vivid-blue};
	--bs-btn-border-radius: 0.375rem;

	justify-content: space-between;

	&.selected {
		--bs-btn-bg: #{$b1-pale};
		--bs-btn-color: #{$vivid-blue};
	}
}

.btn-unassign {
	--bs-btn-bg: #{$white};
	--bs-btn-color: #{$hero-rose};
	--bs-btn-border-radius: 0.5rem;
	--bs-btn-border-color: transparent;
	--bs-btn-hover-bg: #{$stage2-light};
	--bs-btn-hover-color: #{$stage2-dark};
	--bs-btn-hover-border-color: #{$hero-rose};
	--bs-btn-disabled-color: var(--bs-btn-color);
	--bs-btn-disabled-bg: var(--bs-btn-bg);
	--bs-btn-disabled-opacity: 0.5;
	--bs-btn-focus-box-shadow: 0 0 0 0.125rem rgba($hero-rose, 0.2);

	height: 5rem;
	width: 5rem;
}

@mixin tab-label {
	--bs-btn-bg: #{$white};
	--bs-btn-color: #{$shades-500};
	--bs-btn-border-radius: 99px;
	--bs-btn-border-color: transparent;
	--bs-btn-active-color: #{$shades-800};
	--bs-btn-active-bg: #{$b2-light};
	--bs-btn-hover-bg: #{$white};
	--bs-btn-hover-color: #{$shades-800};
	--bs-btn-hover-border-color: transparent;

	width: 10rem;
	height: 3rem;
	display: flex;
	align-items: center;
	justify-content: center;
}

.tab-label {
	@include tab-label;
}

@mixin label-tag {
	font-weight: 700;
	letter-spacing: -0.008125rem;
}

.tag-label {
	@include label-tag;

	background-color: rgba($color: $vivid-blue-tinted, $alpha: 0.2);
	color: $navy;

	// these don't link, there should be no hover state
	// &:hover {
	// 	background-color: $b2-light;
	// 	color: $shades-800;
	// }
}

.tag-label-error {
	@include label-tag;

	background-color: rgba($color: $stage2-light, $alpha: 0.5);
	color: $stage2-dark;

	&:hover {
		background-color: $stage2-light;
	}
}

@mixin option-label {
	padding: 0.625rem 0.75rem;
	border-radius: 0.625rem;
	font-size: 0.875rem;
	background-color: transparent;
	font-weight: 600;
	line-height: 1.5;
	border: 0;
	text-align: left;
	cursor: pointer;

	&:disabled {
		opacity: 0.5;
		cursor: not-allowed;
	}
}

@mixin pill {
	padding: 0.5rem 0;
	width: 6.25rem;
	border-radius: 99px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	border: 0;
}

.pill {
	@include pill;

	color: $shades-800;

	&.stage-1 {
		background-color: $stage1-light;

		&.dark {
			background-color: $stage-1;

			&:hover {
				background-color: $stage1-dark;
			}
		}
	}

	&.stage-2 {
		background-color: $stage2-light;

		&.dark {
			background-color: $stage2;
			color: $white;

			&:hover {
				background-color: $stage2-dark;
			}
		}
	}

	&.stage-3 {
		background-color: $stage3-light;

		&.dark {
			background-color: $stage3;
			color: $white;

			&:hover {
				background-color: $stage3-dark;
			}
		}
	}

	&.stage-4 {
		background-color: $stage4-light;

		&.dark {
			background-color: $stage4;
			color: $white;

			&:hover {
				background-color: $stage4-dark;
			}
		}
	}

	&.stage-\+4 {
		background-color: $stage4-plus-light;

		&.dark {
			background-color: $stage4-plus;
			color: $white;

			&:hover {
				background-color: $stage4-plus-dark;
			}
		}
	}

	&.stage-5 {
		background-color: $stage5-light;

		&.dark {
			background-color: $stage5;
			color: $white;

			&:hover {
				background-color: $stage5-dark;
			}
		}
	}

	&.stage-6 {
		background-color: $stage6-light;

		&.dark {
			background-color: $stage6;
			color: $white;

			&:hover {
				background-color: $stage6-dark;
			}
		}
	}

	&[class*="stage-7"] {
		background-color: $stage7-light;

		&.dark {
			background-color: $stage7;
			color: $white;

			&:hover {
				background-color: $stage7-dark;
			}
		}
	}

	&.stage-pill {
		flex: 0 1 30%;
		cursor: pointer;
	}
}

.btn-routine-filter {
	--bs-btn-bg: #{$white};
	--bs-btn-color: #{$dark-text};
	--bs-btn-border-color: #{$shades-400};
	--bs-btn-hover-bg: #{$vivid-blue};
	--bs-btn-hover-color: #{$white};
	--bs-btn-hover-border-color: #{$vivid-blue};
	--bs-btn-padding-y: 0.625rem;
	--bs-btn-padding-x: 1rem;
	font-family: $sassoon;
	font-size: 1.125rem;
	font-weight: 500;
}

.btn-remove {
	border: none;
	outline: none;
	padding: 0;
	color: $hero-rose;
	font-size: 14px;
	font-weight: 700;
	line-height: 1.5;
	background-color: transparent;

	&:focus {
		box-shadow: none;
	}
}

.btn-quiz-bottom-left,
.btn-quiz-bottom-right {
	@include body-small;
	--bs-btn-bg: #{$white};
	--bs-btn-color: #{$dark-text};
	--bs-btn-border-color: #{rgba($vivid-blue-tinted, 0.2)};
	--bs-btn-hover-bg: #{$hero-blue};
	--bs-btn-hover-color: #{$white};
	--bs-btn-hover-border-color: #{rgba($b1-pale, 0.5)};
	--bs-btn-focus-box-shadow: 0 0.25rem 1.5rem 0 #{rgba($vivid-blue-tinted, 0.2)};
	position: absolute;
	z-index: $btn-home-corner;
	flex-direction: column;
	gap: 0.25rem;
	width: var(--corner-btn-width);
	height: var(--corner-btn-width);
	padding: 2.25rem 0.625rem;
	border-top-width: 0;
	border-right-width: 0;
	border-bottom-width: 0.125rem;
	border-left-width: 0.125rem;
	border-radius: 0 0 0 2.5rem;
	box-shadow: 0 4px 24px 0 rgba(251, 210, 211, 0.2);

	i {
		width: 3rem;
		height: 3rem;
	}
}

.btn-quiz-bottom-left {
	bottom: 0;
	left: 0;
	border-top-width: 0.125rem;
	border-right-width: 0.125rem;
	border-bottom-width: 0;
	border-left-width: 0;
	border-radius: 0 2.5rem 0 0;
}

.btn-quiz-bottom-right {
	bottom: 0;
	right: 0;
	border-top-width: 0.125rem;
	border-right-width: 0;
	border-bottom-width: 0;
	border-left-width: 0.125rem;
	border-radius: 2.5rem 0 0;
}
