.page-book-complete {
	background-color: $b1-pale;

	.bg {
		color: $light-blue;
		mask-position: center bottom !important;
	}

	.btn-large-button {
		max-width: 27.5rem;
		width: 100%;
	}

	.student-elibrary-book {
		width: 11.25rem;
	}

	.stage-complete-badge {
		border: 1.25rem solid $light-blue;
	}

	.link-read-again {
		&::before {
			border-radius: 50rem;
		}
	}
}
