@keyframes fadeIn {
	0% {
		opacity: 0;
		pointer-events: none;
	}

	100% {
		opacity: 1;
		pointer-events: auto;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
		pointer-events: none;
	}
}

@keyframes shake {
	25% {
		transform: translateX(6px);
	}

	50% {
		transform: translateX(-6px);
	}

	75% {
		transform: translateX(6px);
	}
}

@keyframes raiseUp {
	25% {
		transform: translateY(-14px);
	}

	75% {
		transform: translateY(-14px);
	}

	100% {
		transform: translateY(0);
	}
}

.fade-exit {
	opacity: 0;
	animation-name: fadeOut;
	animation-duration: 0.3s;
	animation-fill-mode: both;
}

.fade-enter,
.fade-appear {
	opacity: 1;
	animation-name: fadeIn;
	animation-duration: 0.3s;
	animation-fill-mode: both;
}

.shake {
	animation: shake;
	animation-duration: 0.6s;
	animation-fill-mode: both;
}

.raise-up {
	animation: raiseUp;
	animation-duration: 1.5s;
	animation-fill-mode: both;
}
