.page-student-elibrary {
	--shelf-bottom-image-bottom: 3rem;
	margin-top: 6rem;

	@include media-breakpoint-up(lg) {
		margin-top: 7.5rem;
	}

	.book-shelves {
		gap: 9vw;
		user-select: none;
	}

	.book-rows {
		gap: 2.5vw;
	}

	.container-elibrary {
		--container-gutter-width: 21.25rem;
	}

	.book-link {
		width: 17.5%;

		&:hover,
		&:focus-visible {
			.student-elibrary-book {
				box-shadow: 0 0.125rem 0 0 rgba(255, 255, 255, 0.5) inset, 0 -0.0625rem 0 0.0625rem rgba(0, 0, 0, 0.2) inset, 0 0.25rem 0.75rem 0 rgba(0, 0, 0, 0.2);
				transform: translateY(-3%) scale(1.1);
			}

			.progress-bar {
				opacity: 0.5;
			}
		}

		&::before {
			display: none;
		}

		.progress-bar {
			bottom: 110%;
			width: 80%;
			height: 6%;
			margin: 0 auto;
			background-color: $light-blue;
			transition: opacity $reg-trans ease;

			&,
			&::after {
				border-radius: 100px;
			}

			&::after {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: max(var(--book-progress), 1.25vw);
				height: 100%;
				// background: url("./../../../../public/img/progress-gradient.png") bottom left / 46px 29px repeat;
				background-image: repeating-linear-gradient(120deg, $hero-blue, $hero-blue 0.5vw, #0097e1 0.5vw, #0097e1 1vw);
			}

			&.is-complete {
				&::after {
					width: 100%;
					background: #c7e5b4;
					box-shadow: 0 -0.125rem 0.5rem 0 rgba(255, 255, 255, 0.4) inset;
				}
			}

			.img-complete {
				width: 35%;
				height: auto;
				aspect-ratio: 64 / 68;
			}
		}
	}

	.shelf-bottom-image {
		--bottom-shelf-offset: 8%;
		position: absolute;
		top: 90%;
		left: calc(var(--bottom-shelf-offset) * -1);
		width: calc(100% + var(--bottom-shelf-offset) * 2);
		max-width: none;
	}
}
