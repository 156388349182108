.class-assign-books-page {
	.checkbox-wrapper {
		position: absolute;
		bottom: 0;
		right: 0;
		background-color: $white;
		height: 2rem;
		width: 2rem;
		border-radius: 0.5rem 0 0;

		.book-checkbox {
			position: absolute;
			bottom: 0.125rem;
			right: 0.125rem;
		}
	}
}

.book-stage-accordion {
	--bs-accordion-border-radius: 1rem;
	--bs-accordion-inner-border-radius: 0.9rem; // slight difference because of Chrome half pixels

	&:has(.accordion-button:is(:hover, :focus)) {
		box-shadow: none;
	}

	.accordion-item {
		--custom-accordion-light: #{$stage1-light};
		--custom-accordion-main: #{$stage-1};
		--custom-accordion-dark: #{$stage1-dark};
		--custom-label-text-colour: #{$primary};
		--custom-dark-icon: url("../../../public/svg/chevron-down.svg");
		--custom-light-icon: url("../../../public/svg/chevron-down-white.svg");
		--custom-accordion-button-icon: var(--custom-dark-icon);
		--custom-accordion-button-active-icon: var(--custom-dark-icon);
		--bs-accordion-border-color: transparent;
		--bs-accordion-btn-bg: var(--custom-accordion-main);
		background-color: var(--bs-accordion-btn-bg);
		border-radius: var(--bs-accordion-border-radius);
		border-width: 0;
		position: relative;
		scroll-margin-top: 6.25rem;
		transition: box-shadow $reg-trans ease;

		&:has(.accordion-button[aria-expanded="true"]):has([data-has-books="true"]) {
			box-shadow: 0 0 0 0.25rem var(--custom-accordion-light);

			.accordion-header {
				.accordion-button {
					border-bottom-left-radius: 0;
					border-bottom-right-radius: 0;
					background-color: var(--custom-accordion-main);

					&::before {
						width: 100%;
						border-radius: 0;
					}

					&::after {
						background-color: var(--custom-accordion-dark);
						background-image: escape-svg(var(--custom-accordion-button-active-icon));
					}
				}
			}
		}

		&:has([data-has-books="false"]) {
			pointer-events: none;
			opacity: 0.2;

			.accordion-header {
				.accordion-button {
					&::after {
						transform: none; // nuking the arrow rotate, since we want the accordion to 'look' closed if there's no books
					}
				}
			}

			.accordion-collapse {
				display: none !important;
			}
		}

		&.stage-2 {
			--custom-accordion-light: #{$stage2-light};
			--custom-accordion-main: #{$stage2};
			--custom-accordion-dark: #{$stage2-dark};
			--custom-label-text-colour: #{$white};
			--custom-accordion-button-icon: var(--custom-light-icon);
			--custom-accordion-button-active-icon: var(--custom-light-icon);
		}

		&.stage-3 {
			--custom-accordion-light: #{$stage3-light};
			--custom-accordion-main: #{$stage3};
			--custom-accordion-dark: #{$stage3-dark};
			--custom-label-text-colour: #{$white};
			--custom-accordion-button-icon: var(--custom-light-icon);
			--custom-accordion-button-active-icon: var(--custom-light-icon);
		}

		&.stage-4 {
			--custom-accordion-light: #{$stage4-light};
			--custom-accordion-main: #{$stage4};
			--custom-accordion-dark: #{$stage4-dark};
			--custom-label-text-colour: #{$white};
			--custom-accordion-button-icon: var(--custom-light-icon);
			--custom-accordion-button-active-icon: var(--custom-light-icon);
		}

		&.stage-\+4 {
			--custom-accordion-light: #{$stage4-plus-light};
			--custom-accordion-main: #{$stage4-plus};
			--custom-accordion-dark: #{$stage4-plus-dark};
			--custom-label-text-colour: #{$white};
			--custom-accordion-button-icon: var(--custom-light-icon);
			--custom-accordion-button-active-icon: var(--custom-light-icon);
		}

		&.stage-5 {
			--custom-accordion-light: #{$stage5-light};
			--custom-accordion-main: #{$stage5};
			--custom-accordion-dark: #{$stage5-dark};
			--custom-label-text-colour: #{$white};
			--custom-accordion-button-icon: var(--custom-light-icon);
			--custom-accordion-button-active-icon: var(--custom-light-icon);
		}

		&.stage-6 {
			--custom-accordion-light: #{$stage6-light};
			--custom-accordion-main: #{$stage6};
			--custom-accordion-dark: #{$stage6-dark};
			--custom-label-text-colour: #{$white};
			--custom-accordion-button-icon: var(--custom-light-icon);
			--custom-accordion-button-active-icon: var(--custom-light-icon);
		}

		&[class*="stage-7"] {
			--custom-accordion-light: #{$stage7-light};
			--custom-accordion-main: #{$stage7};
			--custom-accordion-dark: #{$stage7-dark};
			--custom-label-text-colour: #{$white};
			--custom-accordion-button-icon: var(--custom-light-icon);
			--custom-accordion-button-active-icon: var(--custom-light-icon);
		}

		.accordion-header {
			position: relative;

			.accordion-button {
				border-radius: var(--bs-accordion-inner-border-radius);
				overflow: hidden;
				position: relative;
				min-height: 6.25rem;
				background-color: var(--custom-accordion-light);
				padding: 0.75rem 1.25rem;

				&:hover,
				&:focus-visible {
					background-color: var(--custom-accordion-main);
				}

				&::before {
					content: "";
					position: absolute;
					border-radius: 0 3.125rem 3.125rem 0;
					top: 0;
					left: 0;
					width: 10rem;
					height: 100%;
					background-color: var(--custom-accordion-main);
				}

				&::after {
					z-index: 2;
					background-color: var(--custom-accordion-main);
					background-image: escape-svg(var(--custom-accordion-button-icon));
				}

				&:hover,
				&:focus-visible {
					> * {
						color: $primary;
					}

					.attribute-item {
						background-color: var(--custom-accordion-light);
					}

					&::after {
						background-color: var(--custom-accordion-dark);
						background-image: escape-svg(var(--custom-accordion-button-active-icon));
					}
				}

				.attributes {
					padding-left: 1.25rem;
					gap: 0.25rem;
					flex-flow: row wrap;
					font-family: $sassoon;

					.attribute-item {
						padding: 0.125rem;
						border-radius: 0.625rem;
						gap: 0.25rem;
						background-color: rgba($color: $white, $alpha: 0.6);
						display: flex;
						align-items: center;
						font-size: 0.875rem;
						font-style: normal;
						font-weight: 500;
						line-height: 100%;

						p {
							margin-bottom: 0;
							padding: 0.625rem;
						}

						.attribute-btn {
							border-radius: 0.5rem;
							border-top: 0.0625rem solid var(--custom-accordion-main);
							border-right: 0.0625rem solid var(--custom-accordion-main);
							border-bottom: 0.1875rem solid var(--custom-accordion-main);
							border-left: 0.0625rem solid var(--custom-accordion-main);
							background: $white;
							height: 2rem;
							padding: 0.625rem;
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}

				.stage-label,
				.attributes {
					// Set to bring the content above the hover animation
					z-index: 2;
				}

				.stage-label {
					min-width: 8.75rem;
					min-height: 4.625rem;
					color: var(--custom-label-text-colour);
				}
			}
		}

		.accordion-collapse {
			padding: 0 0.625rem 0.625rem;
			background-color: transparent;

			.accordion-body {
				background-color: $white;
				border-radius: 0.5rem;
			}
		}
	}
}
