.page-trial-request-complete {
	padding: 40px 0;
	font-size: 16px; // overwriting base-font-size used in main product

	@include media-breakpoint-up(md) {
		padding: 80px 0;
	}

	.container-buyer-journey {
		gap: 40px;

		@include media-breakpoint-up(md) {
			gap: 80px;
		}
	}
}
