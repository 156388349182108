.divider {
	display: flex;
	align-items: center;
	gap: 0.75rem;
	margin-block: 2rem;

	.left,
	.right {
		width: 50%;
		height: 0.125rem;
		background-color: $shades-400;
	}

	&.milo-blue {
		.left,
		.right {
			background-color: $milo-blue;
		}

		.h4 {
			color: $milo-blue;
		}
	}
}
