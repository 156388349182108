.layout-student {
	background-color: $white;

	.top-nav {
		margin: 2.5rem 2.5rem 0;
	}

	.logo {
		display: flex;
		align-items: center;
		justify-content: center;

		&::before {
			display: none;
		}
	}

	.top-nav-link {
		width: 100%;
		max-width: 10rem;
		padding-top: 1.25rem;
		padding-bottom: 1.25rem;
		text-decoration: none;

		@include media-breakpoint-up(lg) {
			max-width: 15rem;
		}

		&,
		&::before {
			border-radius: 1.25rem;
		}
	}

	.top-nav-link-text {
		@include media-breakpoint-between(0, lg) {
			font-size: 1.125rem;
		}
	}

	.books-bottom {
		margin-top: 7.5rem;
	}

	.side-up-down-buttons {
		height: 50vh;

		.btn {
			--bs-btn-border-color: #{rgba($b1-pale, 0.5)};
			--bs-btn-bg: #{$hero-blue};
			--bs-btn-hover-bg: #{$vivid-blue};
			--bs-btn-hover-color: #{$white};
			--bs-btn-hover-border-color: var(--bs-btn-border-color);
			--bs-btn-disabled-bg: var(--bs-btn-bg);
			--bs-btn-disabled-border-color: var(--bs-btn-border-color);
			--bs-btn-disabled-color: var(--bs-btn-color);
			--bs-btn-disabled-opacity: 0.2;
			--bs-btn-border-width: 0.125rem;
			--bs-btn-border-radius: 2.5rem;
			width: auto;
			height: auto;
			border-right: 0;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			padding: min(1.5vw, 1.5rem) min(0.8vw, 0.8rem) min(1.5vw, 1.5rem) min(1.5vw, 1.5rem);
			box-shadow: 0 0.25rem 1.5rem 0 rgba(0, 183, 241, 0.2);
			transition-property: background-color, color, transform, opacity, box-shadow;
			transition-duration: $long-trans, $long-trans, $long-trans, $reg-trans, $long-trans;
			transition-timing-function: ease;

			&:focus {
				box-shadow: none;
			}

			i {
				width: min(3vw, 3rem) !important;
				height: auto !important;
				aspect-ratio: 1;
			}
		}
	}

	.btn-profile-top-right {
		--bs-btn-bg: transparent;
		--bs-btn-color: #{$dark-text};
		--bs-btn-hover-bg: transparent;
		--bs-btn-hover-color: var(--bs-btn-color);
		--bs-border-width: 0;
		--bs-btn-focus-box-shadow: 0 0 0 0.125rem #{rgba($stage2-light, 0.2)};
	}
}
