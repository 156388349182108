.nav-pills {
	gap: 0.75rem;

	.nav-pill {
		display: flex;
		height: 3rem;
		padding: 0.75rem 1.5rem;
		justify-content: center;
		align-items: center;
		gap: 0.625rem;
		border-radius: 99px;
		border: 0;
		background-color: transparent;
		position: relative;
		color: $shades-500;
		transition: color $reg-trans ease;

		&:hover,
		&.active {
			color: $shades-800;
		}

		&::after {
			content: "";
			display: block;
			width: 100%;
			height: 0.125rem;
			background-color: transparent;
			position: absolute;
			left: 0;
			bottom: -1.1rem;
		}

		&.active {
			background-color: rgba($color: $vivid-blue-tinted, $alpha: 0.2);

			span {
				color: $vivid-blue;
			}

			&::after {
				background-color: $hero-blue;
			}
		}
	}
}
