.bordered-list {
	&.teachers-list {
		.content {
			.icon {
				padding: 0.75rem;
				border-radius: 0.75rem;
				display: flex;
				justify-content: center;
				align-items: center;

				&.active {
					background-color: $b1-pale;
				}

				&.pending {
					background-color: $shades-200;
					color: $shades-400;
				}
			}
		}
	}
}

.actions-dropdown {
	z-index: $zindex-offcanvas + 1;
	display: flex;
	flex-flow: column nowrap;
	gap: 0.25rem;
	border-radius: 0.75rem;
	background-color: $aura-blue;
	border: 0 solid $shades-200;
	box-shadow: 0 0.75rem 1.25rem 0 rgba(41, 37, 98, 0.1);
	position: absolute;
	top: calc(100% + 0.5rem);
	right: calc(var(--grid-gutter-width) / 2);
	padding: 0;
	min-width: 12.5rem;
	height: 0;
	transition: height $reg-trans ease-in-out;

	> * {
		display: none;
	}

	&.show {
		height: auto;
		padding: 0.5rem;
		border-width: 0.0625rem;

		> * {
			display: block;
		}
	}
}

.offcanvas {
	&.view-teacher {
		.offcanvas-header {
			padding-bottom: 2.5rem;
		}

		.icon-wrapper {
			width: 5rem;
			height: 5rem;
			background-color: $light-blue;
			border-radius: 1.25rem;
			display: flex;
			justify-content: center;
			align-items: center;
			margin-bottom: 1.875rem;
		}

		.class-row {
			display: flex;
			gap: 1.25rem;
			align-items: center;
			background-color: $white;
			border: 0.0625rem solid $shades-400;
			border-radius: 1.25rem;
			padding: 1.25rem;

			.actions-dropdown {
				right: 0;
			}

			.class-icon-wrapper {
				width: 3rem;
				height: 3rem;
				border-radius: 0.75rem;
				display: flex;
				justify-content: center;
				align-items: center;
				border: 0.0625rem dashed $shades-400;
			}

			.students-icon {
				width: 3rem;
				height: 3rem;
				border-radius: 0.75rem;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: rgba($color: $milo-blue, $alpha: 0.2);
			}
		}

		.offcanvas-body {
			gap: 0.75rem;
		}
	}
}
