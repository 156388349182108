.ftux {
	display: flex;
	flex-direction: column;
	gap: 1.25rem;
	width: 18.75rem;
	padding: 1.25rem;
	border-radius: 1rem;
	background-color: $b1-pale;
}

// lol
.__floater.__floater__open {
	// overwrite inline styles
	filter: drop-shadow(0 0 1px rgba(45, 62, 80, 1)) !important; // gives us a border-ish effect that matches design
}
