.page-routines {
	.routine-cards-wrapper {
		padding: 1.25rem;
	}

	.btn-routine-card {
		--bs-btn-bg: #{$white};
		--bs-btn-color: #{$dark-text};
		--bs-btn-border-color: #{$shades-400};
		--bs-btn-hover-bg: var(--bs-btn-bg);
		--bs-btn-hover-color: var(--bs-btn-color);
		--bs-btn-hover-border-color: #{$hero-blue};
		flex-grow: 1;
		width: 100%;
		max-width: 9rem;
		padding: 0.75rem;

		&:hover {
			box-shadow: var(--bs-btn-focus-box-shadow);
		}
	}

	.routine-btn-info {
		--bs-btn-bg: #{$white};
		--bs-btn-color: #{$dark-text};
		--bs-btn-hover-bg: var(--bs-btn-bg);
		--bs-btn-hover-color: #{$vivid-blue};
		--bs-btn-border-radius: 0.75rem 0 0 0;
		--bs-btn-padding-y: 0;
		--bs-btn-padding-x: 0;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		width: 1.5rem;
		aspect-ratio: 1;
	}

	.routine-card-img-wrapper {
		width: 100%;
		aspect-ratio: 1;
		background-color: $b1-pale;
		border-radius: 0.5rem;
		transition: background-color $long-trans ease;

		.cover-image-placeholder {
			width: 3rem;
			height: 3rem;
		}
	}

	.routine-card-text {
		min-height: 4rem;
		font-weight: 500;
		line-height: 1.4;
	}
}

.offcanvas {
	&.routine-selector {
		.offcanvas-body {
			gap: 2.5rem;
		}

		.routine-selector-wrapper {
			background-color: $white;
			border: 0.0625rem solid $shades-400;
			border-radius: 0.75rem;
		}

		.routine-selector-item {
			padding: 1.5rem;
		}
	}
}
