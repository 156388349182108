.hint-message-component {
	background-color: $b1-pale;
	padding: 1.5rem 3rem 1.5rem 1.5rem;
	border-radius: 1rem;

	.message {
		padding-left: 0.75rem;
		display: flex;
		flex-flow: column nowrap;
		gap: 0.25rem;
		text-wrap: pretty;
		white-space: pre-wrap;
	}
}
