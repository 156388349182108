.page-feedback {
	background: url(./../../../public/svg/bg-squiggles.svg) center bottom / contain no-repeat transparent;

	.content-wrapper {
		margin: 2.5rem 0;
	}

	.main-content {
		max-width: 62.5rem;
		gap: 3.75rem;
		padding: 3.75rem 5rem;
		border-radius: 2.5rem;
		background-color: $white;
		box-shadow: 0 0.5rem 0.75rem 0 rgba(45, 62, 80, 0.2);
	}

	.btn-feedback {
		--bs-btn-focus-box-shadow: none;
		transition-property: transform, opacity;
		transition-duration: $reg-trans;
		transition-timing-function: ease;

		&:hover,
		&:focus-visible,
		&.active {
			transform: scale(1.1);
		}

		&:disabled {
			opacity: 0.5;
		}
	}
}
