.student-details-page {
	.btn-more {
		--bs-btn-bg: #{$b1-pale};
	}

	.actions-dropdown {
		right: auto;
		left: 0;
	}

	.title-section-sub {
		padding-bottom: 0;
	}

	.stage-tab-wrapper {
		--stage-tab-height: 64px;
		height: var(--stage-tab-height);

		.stage-tab {
			--bs-btn-padding-x: 0.625rem;
			--bs-btn-padding-y: 1rem;
			--bs-btn-font-size: 1rem;
			--bs-btn-border-radius: 0;
			--bs-btn-color: #{$shades-400};
			--bs-btn-bg: #{$shades-100};
			--bs-btn-focus-box-shadow: none;
			--bs-btn-disabled-bg: #{$shades-100};
			--bs-btn-disabled-color: #{$shades-400};
			--bs-btn-disabled-opacity: 1;

			border-radius: 300rem 300rem 0 0;
			width: 3rem;
			height: 3rem;

			&.active {
				height: var(--stage-tab-height);
			}

			&.bg-stage-1 {
				--bs-btn-active-bg: #{$stage-1};
				--bs-btn-active-color: #{$primary};
				--bs-btn-hover-color: #{$primary};
				--bs-btn-hover-bg: #{$stage1-dark};

				&.assigned {
					--bs-btn-bg: #{$stage1-light};
					--bs-btn-color: #{$primary};
				}
			}

			&.bg-stage-2 {
				--bs-btn-active-bg: #{$stage2};
				--bs-btn-active-color: #{$white};
				--bs-btn-hover-color: #{$white};
				--bs-btn-hover-bg: #{$stage2-dark};

				&.assigned {
					--bs-btn-bg: #{$stage2-light};
					--bs-btn-color: #{$primary};
				}
			}

			&.bg-stage-3 {
				--bs-btn-active-bg: #{$stage3};
				--bs-btn-active-color: #{$white};
				--bs-btn-hover-color: #{$white};
				--bs-btn-hover-bg: #{$stage3-dark};

				&.assigned {
					--bs-btn-bg: #{$stage3-light};
					--bs-btn-color: #{$primary};
				}
			}

			&.bg-stage-4 {
				--bs-btn-active-bg: #{$stage4};
				--bs-btn-active-color: #{$white};
				--bs-btn-hover-color: #{$white};
				--bs-btn-hover-bg: #{$stage4-dark};

				&.assigned {
					--bs-btn-bg: #{$stage4-light};
					--bs-btn-color: #{$primary};
				}
			}

			&.bg-stage-\+4 {
				--bs-btn-active-bg: #{$stage4-plus};
				--bs-btn-active-color: #{$white};
				--bs-btn-hover-color: #{$white};
				--bs-btn-hover-bg: #{$stage4-plus-dark};

				&.assigned {
					--bs-btn-bg: #{$stage4-plus-light};
					--bs-btn-color: #{$primary};
				}
			}

			&.bg-stage-5 {
				--bs-btn-active-bg: #{$stage5};
				--bs-btn-active-color: #{$white};
				--bs-btn-hover-color: #{$white};
				--bs-btn-hover-bg: #{$stage5-dark};

				&.assigned {
					--bs-btn-bg: #{$stage5-light};
					--bs-btn-color: #{$primary};
				}
			}

			&.bg-stage-6 {
				--bs-btn-active-bg: #{$stage6};
				--bs-btn-active-color: #{$white};
				--bs-btn-hover-color: #{$white};
				--bs-btn-hover-bg: #{$stage6-dark};

				&.assigned {
					--bs-btn-bg: #{$stage6-light};
					--bs-btn-color: #{$primary};
				}
			}

			&[class*="bg-stage-7"] {
				--bs-btn-active-bg: #{$stage7};
				--bs-btn-active-color: #{$white};
				--bs-btn-hover-color: #{$white};
				--bs-btn-hover-bg: #{$stage7-dark};

				&.assigned {
					--bs-btn-bg: #{$stage7-light};
					--bs-btn-color: #{$primary};
				}
			}
		}
	}

	.content {
		.title-bar {
			height: 100px;
			padding: 0 1.62rem;
			border-radius: 0.75rem;

			.btn-title-unassign {
				--bs-btn-border-color: #{$hero-rose};
				--bs-btn-hover-bg: #{$stage2-light};
				--bs-btn-hover-color: #{$stage2-dark};
				--bs-btn-hover-border-color: var(--bs-btn-border-color);
			}
		}

		.empty-state {
			padding: 3.75rem 0;
			background-color: $shades-100;
			border-radius: 0.75rem;
			border: 0.0625rem solid $shades-400;
			color: $shades-500;
			gap: 0.75rem;
		}

		.series-row {
			border-radius: 0.75rem;

			.series-title {
				min-height: 5.125rem;
			}

			.booklist {
				.accordion {
					--bs-accordion-border-color: none;
					--bs-accordion-btn-bg: $white;
					--bs-accordion-bg: none;
					--bs-accordion-active-bg: #{$aura-blue};
					--bs-accordion-btn-bg: #{$white};
					--bs-accordion-btn-active-bg: var(--bs-accordion-active-bg);
					--bs-accordion-padding-y: 0.625rem;
					--bs-accordion-padding-x: 0.625rem;
					--bs-accordion-btn-padding-y: var(--bs-accordion-padding-y);
					--bs-accordion-btn-padding-x: var(--bs-accordion-padding-x);

					// 0 padding because of the divider lines
					--bs-accordion-body-padding-x: 0;
					--bs-accordion-body-padding-y: 0;

					--bs-accordion-border-radius: 0.5rem;
					--bs-accordion-inner-border-radius: 0.5rem;

					&:has(.accordion-button:is(:hover, :focus)) {
						box-shadow: none;
					}

					.image-or-text-start-wrapper {
						flex-shrink: 0;

						@include media-breakpoint-up(lg) {
							width: 3rem;
							text-align: center;
						}
					}

					.accordion-header-custom-width-alignment {
						width: calc(100% - var(--accordion-button-width)); // accounting for accordion button, which needs to be pos: abs; so its width doesn't impact the row alignments
					}

					.accordion-body-custom-width-alignment {
						width: calc(100% - var(--accordion-button-width) - 1rem);
					}

					.accordion-header {
						.accordion-button {
							padding-right: 1rem;

							&[aria-expanded="true"] {
								border-bottom-right-radius: 0;
								border-bottom-left-radius: 0;
							}

							&:hover,
							&:focus {
								> * {
									color: $primary;
								}
							}

							&::after {
								position: absolute;
								right: 1rem;
							}
						}
					}

					.accordion-collapse {
						background-color: $aura-blue;

						.accordion-body {
							padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
							padding-right: 1rem;
						}
					}

					.activity-wrapper {
						min-height: 3.5rem;
						padding: 0.625rem var(--bs-accordion-padding-x);
						border-top: 0.0625rem solid $shades-200;

						.status-bar {
							.activity-status-icon {
								width: 1.5rem;
								height: 1.5rem;
								border-radius: 50%;
								display: flex;
								align-items: center;
								justify-content: center;

								&::before {
									@extend %svg-mask;

									content: "";
									display: block;
									width: 1rem;
									height: 1rem;
									background-size: contain;
									color: $white;
								}
							}

							&.correct {
								color: $turquoise;

								.activity-status-icon {
									background-color: $turquoise;

									&::before {
										--svg-mask-image: url("../../../public/svg/check.svg");
									}
								}
							}

							&.incorrect {
								color: $hero-rose;

								.activity-status-icon {
									background-color: $hero-rose;

									&::before {
										--svg-mask-image: url("../../../public/svg/cross.svg");
									}
								}
							}
						}
					}
				}
			}
		}

		.status-icon {
			width: 1.5rem;
			height: 1.5rem;
			border-radius: 50%;
			border: 0.25rem solid $shades-200;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.progress-inprogress {
			color: $golden;

			.status-icon-dynamic {
				--progress-total: calc(360deg * calc(var(--progress-variable, 0) / 100));

				background: conic-gradient($golden 0deg var(--progress-total), $shades-200 var(--progress-total) 360deg);
				border: 0;

				&::before {
					content: "";
					display: block;
					width: 1rem;
					height: 1rem;
					background-size: contain;
					background-color: $white;
					border-radius: 50%;
				}
			}
		}

		.progress-complete {
			color: $turquoise;

			.status-icon {
				background-color: $turquoise;
				border-width: 0;
				color: $white;

				&::before {
					@extend %svg-mask;

					--svg-mask-image: url("../../../public/svg/check.svg");

					content: "";
					display: block;
					width: 1rem;
					height: 1rem;
					background-size: contain;
				}
			}
		}

		.dot {
			width: 1.5rem;
			height: 1.5rem;
			display: flex;
			align-items: center;
			justify-content: center;

			&::before {
				content: "";
				display: block;
				width: 0.625rem;
				height: 0.625rem;
				background-color: $shades-200;
				border-radius: 50%;
			}
		}

		&.stage-1 {
			.title-bar {
				background-color: $stage-1;
				color: $shades-800;
			}

			.series-row {
				background-color: $stage1-light;
			}
		}

		&.stage-2 {
			.title-bar {
				background-color: $stage2;
				color: $white;
			}

			.series-row {
				background-color: $stage2-light;
			}
		}

		&.stage-3 {
			.title-bar {
				background-color: $stage3;
				color: $white;
			}

			.series-row {
				background-color: $stage3-light;
			}
		}

		&.stage-4 {
			.title-bar {
				background-color: $stage4;
				color: $white;
			}

			.series-row {
				background-color: $stage4-light;
			}
		}

		&.stage-\+4 {
			.title-bar {
				background-color: $stage4-plus;
				color: $white;
			}

			.series-row {
				background-color: $stage4-plus-light;
			}
		}

		&.stage-5 {
			.title-bar {
				background-color: $stage5;
				color: $white;
			}

			.series-row {
				background-color: $stage5-light;
			}
		}

		&.stage-6 {
			.title-bar {
				background-color: $stage6;
				color: $white;
			}

			.series-row {
				background-color: $stage6-light;
			}
		}

		&[class*="stage-7"] {
			.title-bar {
				background-color: $stage7;
				color: $white;
			}

			.series-row {
				background-color: $stage7-light;
			}
		}
	}
}
