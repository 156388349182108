// general use for changing bootstrap column widths via css
@mixin column-width($new-col-width) {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: $new-col-width;
	max-width: $new-col-width;
}

@mixin explicit-column-width($new-col-width) {
	flex-grow: 0;
	flex-shrink: 0;
	flex-basis: $new-col-width;
	max-width: $new-col-width;
	width: $new-col-width;
}

@mixin aspect-ratio($width, $height) {
	position: relative;

	&::before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}

	> .content {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

@mixin line-clamp($line-number) {
	display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: $line-number;
	overflow: hidden;
}

// Ellipsis
@mixin excerpt($font-size: $base-font-size, $lines-to-show: 2, $excerpt-bg: transparent) {
	background: $excerpt-bg;
	display: block; /* Fallback for non-webkit */
	display: -webkit-box;
	font-size: $font-size;
	-webkit-line-clamp: $lines-to-show;
	-webkit-box-orient: vertical;
	overflow: hidden;
	text-overflow: ellipsis;
}

@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

@mixin text-link {
	--bs-btn-border-radius: 0.5rem; // so matches the border-radius of the pseudo, and focus-state with the border matches up
	--bs-btn-focus-box-shadow: none;
	position: relative;
	z-index: 1; // so the psuedo hover can appear underneath the link, but not a component background
	display: inline-flex;
	font-size: var(--base-font-size);
	line-height: var(--base-line-height);
	font-weight: 700;
	padding: 0;
	text-decoration: underline;

	&:not(:disabled) {
		&:hover,
		&:focus-visible,
		&.active {
			// design has forced our hand in using this ::before, because the hover state has padding around it but we don't want that affecting other text...
			// need to be turned off for links that look like buttons and certain elements
			// these are controlled in structure.scss
			&::before {
				background-color: rgba($vivid-blue-tinted, 0.2);
			}
		}
	}

	&:focus-visible {
		outline: 0;
		box-shadow: $input-btn-focus-box-shadow;
	}

	&::before {
		--offset-x: 0.5rem;
		--offset-y: 0.25rem;
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
		width: calc(100% + var(--offset-x));
		height: calc(100% + var(--offset-y));
		background-color: rgba($vivid-blue-tinted, 0);
		border-radius: var(--bs-btn-border-radius);
		pointer-events: none;
		transition: background-color $reg-trans ease;
	}

	i {
		width: 1rem;
		height: 1rem;
	}
}
